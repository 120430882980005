import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormActions from "../../components/formactions";
import Textfield from "../../components/textfield/textfield";
import { FormContext } from "./FormContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { MinimumLease } from "./minimumLease";
import { FiDollarSign } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import MinimumLeaseDropdown from "./minimumLeaseRange";
import Button from "../../components/button";
let isAnyFieldFilled = false;

let lastAvailableIsFuture = false; // Define the global variable
let isNumSpotsPositive = false;
let isLease = false;

const schema = yup.object({
  numSpots: yup
    .number()
    .test(
      "is-positive",
      "numSpots must be a positive number",
      function (value) {
        if (value === undefined || value === null) {
          // Optional field, no validation required
          return true;
        }
        if (isNaN(value)) {
          return true;
        }
        isNumSpotsPositive = value > 0; // Store the boolean value indicating if numSpots is positive or not
        return value > 0;
      }
    )
    .required(),
  firstAvailable: yup.date().required(),
  lastAvailable: yup
    .date()
    .test(
      "is-future-date",
      "Last available date must be later than first available date",
      function (value) {
        if (value instanceof Date) {
          lastAvailableIsFuture =
            value > (this.resolve(yup.ref("firstAvailable")) as Date); // Assign the result to the global variable
          return lastAvailableIsFuture;
        }
        return false;
      }
    ),
  minimumLeaseRange: yup.number().min(-1).required(),
  /*minimumLeaseRange: yup.string().required().test('is-custom-specified', 'You must input a number for a custom lease range', function(value){
    if (value === "Custom") {
      return false;
    }
    return true;
  }),*/
  perWeek: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null; // Convert empty string to NaN
      }
      return value;
    })
    .positive()
    .nullable()
    .default(null),
  perDay: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null; // Convert empty string to NaN
      }
      return value;
    })
    .positive()
    .nullable()
    .default(null),

  perMonth: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null; // Convert empty string to NaN
      }
      return value;
    })
    .positive()
    .nullable()
    .default(null),
  perLease: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null; // Convert empty string to NaN
      }
      return value;
    })
    .positive()
    .nullable()
    .default(null),
  upFront: yup.boolean().default(false),
  proRate: yup.boolean().default(false),
});
// .test({
//   name: 'atLeastOneFilled',
//   test: function (values) {
//     isLease = !!values.perLease;
//     isAnyFieldFilled = !!values.perDay || !!values.perWeek || !!values.perMonth
//     console.log("isAnyFieldFilled:", isAnyFieldFilled);
//     console.log("isNumSpotsPositive", isNumSpotsPositive);
//     console.log("first", lastAvailableIsFuture);
//     return true;
//   },
//   message: 'At least one of perDay, perWeek, or perMonth must be filled',
// });

interface FormValues extends yup.InferType<typeof schema> {}

export const PricingAvailabilityForm = () => {
  let [formData, setFormData] = useContext(FormContext);

  // console.log("state");
  // console.log(formData);

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      numSpots: formData.numberOfSpaces,
      /* @ts-ignore */
      firstAvailable: dtf(formData.period?.start),
      /* @ts-ignore */
      lastAvailable: dtf(formData.period?.end),
      minimumLeaseRange: formData.minimumLeaseRange
        ? formData.minimumLeaseRange
        : 0,
      perDay: formData.pricing?.daily,
      perWeek: formData.pricing?.weekly,
      perMonth: formData.pricing?.monthly,
    },
  });

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const navigate = useNavigate();

  const [selectedTabValue, setSelectedTabValue] = useState("None");
  const [leaseFormat, setLeaseFormat] = useState(false);
  const [visible, setVisible] = useState(false);

  const theNumSpots = watch("numSpots");
  const theFirstAvailable = watch("firstAvailable");
  const theLastAvailable = watch("lastAvailable");
  const theMinimumLease = watch("minimumLeaseRange");
  const thePerDay = watch("perDay");
  const thePerWeek = watch("perWeek");
  const thePerMonth = watch("perMonth");
  const thePerLease = watch("perLease");

  const handleFormatClick = () => {
    setLeaseFormat(!leaseFormat);
  };

  const onSubmit = handleSubmit((data) => {
    console.log(data.minimumLeaseRange);
    setFormData({
      ...formData,
      numberOfSpaces: data.numSpots,
      period: {
        start: data.firstAvailable,
        end: data.lastAvailable,
      },
      minimumLeaseRange: data.minimumLeaseRange,
      pricing: {
        daily: data.perDay !== null ? data.perDay : undefined,
        weekly: data.perWeek !== null ? data.perWeek : undefined,
        monthly: data.perMonth !== null ? data.perMonth : undefined,
      },
      upfrontOnly: data.upFront,
      prorate: data.proRate,
    });

    navigate("details");
  });

  return (
    <div>
      {/* <Button text = "Test submit" onClick = {()=> console.log(theNumSpots <= 0 || !theLastAvailable || !theFirstAvailable || !theMinimumLease
        || theMinimumLease === "Custom" || (leaseFormat ? !thePerLease : !thePerDay && !thePerMonth && !thePerWeek))}/> */}
      <div className="text-center text-2xl font-bold mt-[1vh]">
        Pricing and Availability
      </div>
      <form onSubmit={onSubmit}>
        <Textfield
          {...register("numSpots", { required: false, valueAsNumber: true })}
          label={"Number of Spots"}
          margins="less"
          error={errors.numSpots ? true : false}
          validationMessage={
            errors.numSpots ? "You must enter a number of spots" : ""
          }
        />
        <div className="flex justify-between">
          <div className="ml-4 sm:ml-12">
            <Textfield
              type="date"
              {...register("firstAvailable", { required: false })}
              label={"First Date Available"}
              margins="less"
              stage="first"
              error={errors.firstAvailable ? true : false}
              validationMessage={
                errors.firstAvailable ? "You must enter a valid date" : ""
              }
            />
          </div>
          <div className="mr-4 sm:mr-12">
            <Textfield
              type="date"
              {...register("lastAvailable", { required: false })}
              label={"Last Date Available"}
              stage="last"
              error={errors.lastAvailable ? true : false}
              validationMessage={
                errors.lastAvailable ? "You must enter a valid date" : ""
              }
              margins="less"
            />
          </div>
        </div>
        {/* <p className={"text-bold"}>Minimum Lease Range</p>
        <select {...register("minimumLeaseRange", { required: false})}>
          <option value={"none"}>None</option>
          <option value={"7"}>7</option>
          <option value={"14"}>14</option>
          <option value={"30"}>30</option>
          <option value={"90"}>90</option>
          <option value={"180"}>180</option>
          <option value={"Custom"}>365</option>
        </select>

        <br /> */}
        <Controller
          control={control}
          name={"minimumLeaseRange"}
          render={({ field: { onChange, value } }) => (
            <MinimumLeaseDropdown onChange={onChange} defaultValue={value} />
          )}
        />
        {errors.minimumLeaseRange && (
          <p className="text-red-500">{errors.minimumLeaseRange.message}</p>
        )}
        <div
          className="ml-8 flex items-center"
          style={{ marginTop: selectedTabValue === "Custom" ? "7vh" : "3vh" }}
        >
          <div className=" text-lg font-bold mr-3">Prices</div>
          {selectedTabValue === "Full Available Range" && (
            <button
              className="text-blue-400 font-medium text-md"
              type="button"
              onClick={handleFormatClick}
            >
              <div>
                {leaseFormat
                  ? "Switch to Calculated Price Format "
                  : "Switch to Lease Price Format"}
              </div>
            </button>
          )}
        </div>
        <div className="mt-[-2vh]"></div>
        {!leaseFormat && (
          <Textfield
            type={"number"}
            {...register("perDay", { required: false })}
            label={"Per Day"}
            validationMessage={errors.perDay?.message}
            margins="less"
            leftAccessory={<FiDollarSign />}
          />
        )}
        {!leaseFormat && watch("perDay") && (
          <p className=" ml-12 mb-[-2vh]">
            You'll earn ${((watch("perDay") ?? 0) * 0.95).toFixed(2)} per day
          </p>
        )}

        {!leaseFormat && (
          <Textfield
            type={"number"}
            {...register("perWeek", { required: false })}
            label={"Per Week"}
            validationMessage={errors.perWeek?.message}
            margins="less"
            leftAccessory={<FiDollarSign />}
          />
        )}
        {!leaseFormat && watch("perWeek") && (
          <p className=" ml-12">
            You'll earn ${((watch("perWeek") ?? 0) * 0.95).toFixed(2)} per week
          </p>
        )}

        {!leaseFormat && (
          <Textfield
            type={"number"}
            {...register("perMonth", { required: false })}
            label={"Per Month"}
            validationMessage={errors.perMonth?.message}
            margins="less"
            leftAccessory={<FiDollarSign />}
          />
        )}
        {!leaseFormat && watch("perMonth") && (
          <p className=" ml-12">
            You'll earn ${((watch("perMonth") ?? 0) * 0.95).toFixed(2)} per
            month
          </p>
        )}

        {leaseFormat && (
          <Textfield
            type={"number"}
            {...register("perLease", { required: false })}
            label={"Per Lease"}
            validationMessage={errors.perLease?.message}
            margins="less"
            leftAccessory={<FiDollarSign />}
          />
        )}
        {leaseFormat && watch("perLease") && (
          <p className=" ml-4 sm:ml-12">
            You'll earn ${((watch("perLease") ?? 0) * 0.95).toFixed(2)} per
            lease
          </p>
        )}
        <div className="flex items-center justify-space">
          <div className="flex items-center align-center mt-[3vh] ml-12">
            {/* Checkbox */}
            <input
              type="checkbox"
              id="checkBox"
              className="h-[3vh] w-[3vh] text-blue-500 rounded-sm mr-4"
              {...register("upFront", { required: false })}
            />

            {/* Container for the two text divs */}
            <div className="flex flex-col h-full">
              <div className="font-medium text-left text-lg font-semibold">
                Upfront Payment
              </div>
            </div>
          </div>
          {leaseFormat && (
            <div className="flex items-center align-center mt-[3vh] ml-12">
              {/* Checkbox */}
              <input
                type="checkbox"
                id="checkBox"
                className="h-[3vh] w-[3vh] text-blue-500 rounded-sm mr-4"
                {...register("proRate", { required: false })}
              />

              {/* Container for the two text divs */}
              <div className="flex flex-col h-full">
                <div className="font-medium text-left text-lg font-semibold">
                  Proration
                </div>
              </div>
            </div>
          )}
        </div>
        <FormActions
          submitText="Continue"
          submitDisabled={
            theNumSpots <= 0 ||
            !theLastAvailable ||
            !theFirstAvailable ||
            !theMinimumLease ||
            (leaseFormat
              ? !thePerLease
              : !thePerDay && !thePerMonth && !thePerWeek)
          }
        />
      </form>
    </div>
  );
};

const dtf = (date?: Date) => {
  if (date == null) return "";
  console.log(date);
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const dt = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${date.getFullYear()}-${month}-${dt}`;
};
