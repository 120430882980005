import { ReactNode } from "react";

export type Props = {
  icon: ReactNode;
  title: string;
};

const Ammenity = ({ icon, title }: Props) => {
  return (
    <div className="flex items-center py-1">
      <div className="mr-2">{icon}</div>
      <p>{title}</p>
    </div>
  );
};

export default Ammenity;
