import { useSpotSearch } from "../hooks/useSpotSearch";
import { SearchQuery } from "./search";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import SpotCard from "./spotcard";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

export interface SearchQueryMapProps {
  query: SearchQuery;
}

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

export const SearchQueryMap = ({ query }: SearchQueryMapProps) => {
  const { loading, spots } = useSpotSearch(query);

  return (
    <MapContainer
      className="h-full"
      center={{
        lat: query.location?.lat ?? 42.27928267921247,
        lng: query.location?.lng ?? -83.74421324458177,
      }}
      zoom={13}
      scrollWheelZoom={true}
    >
      {!loading ? (
        spots.map((spot) => {
          return (
            <Marker
              /*ref={markerRef}
              eventHandlers={eventHandlers}
              draggable={draggable}*/
              icon={DefaultIcon}
              position={[spot.position.lat, spot.position.lng]}
            >
              <Popup>
                <SpotCard
                  spot={spot}
                  key={spot.id}
                  start={query?.dates?.start.toDateString()}
                  end={query?.dates?.end.toDateString()}
                />
              </Popup>
            </Marker>
          );
        })
      ) : (
        <></>
      )}

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};
