import { useNavigate, useParams } from "react-router-dom";
import { gql } from "../__generated__";
import { useQueryParams } from "../hooks/useQueryParams";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { StripeContext } from "../context/StripeProvider";

const CREATE_LEASE_REQUEST =
  gql(`mutation CreateLeaseRequest($data: CreateLeaseRequestData) {
    createLeaseRequest(data: $data) {
      code
      message
      success
      lease {
        id
      }
    }
  }`);

export const Finalize = () => {
  const stripe = useContext(StripeContext);
  const navigate = useNavigate()

  const params = useQueryParams();

  const token = params.get("token");
  const setupIntent = params.get("setup_intent_client_secret");
  stripe.registerApiKey(params.get("key") ?? "");

  const [createLeaseRequest, { loading, error, data }] =
    useMutation(CREATE_LEASE_REQUEST);

  useEffect(() => {
    console.log("effect");
    console.log(token);
    console.log(setupIntent);
    if (!token || !setupIntent) return;

    const getPaymentMethodId = async () => {
      console.log("get pm");
      const stripeLib = await stripe.stripePromise;
      const paymentMethodId = (
        await stripeLib?.retrieveSetupIntent(setupIntent)
      )?.setupIntent?.payment_method as string | null | undefined;
      console.log("ID: " + paymentMethodId);

      if (!paymentMethodId) return;

      createLeaseRequest({
        variables: {
          data: {
            invoice: token,
            paymentMethodId: paymentMethodId,
          },
        },
        onCompleted(data, clientOptions) {
          if(data.createLeaseRequest?.success){
            navigate(`/success/${data.createLeaseRequest.lease?.id ?? "0"}`)
          }
        },
      });
    };

    getPaymentMethodId();
  }, [createLeaseRequest, setupIntent, stripe.stripePromise, token]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center flex flex-col items-center">
        <h2 className="text-2xl font-semibold">Setting up your lease</h2>
        <p className="mt-2 text-gray-600">
          Please wait a moment while we finalize a few details
        </p>
        <div className="mt-4">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-blue-500 border-t-blue-600 border-solid"></div>
        </div>
      </div>
    </div>
  );
};
