import { AuthContext } from "./AuthContext";
import { useState } from "react";
import { tokenFromRefreshToken } from "../helpers/CognitoConnector";
import { TokenEndpointResponse } from "../helpers/CognitoConnector";

export type Props = {
  children?: React.ReactNode;
};

const TOKEN = "token";
const REFRESH_TOKEN = "refresh_token";
const EXPIRATION = "expiration";

export const AuthContextProvider = ({ children }: Props) => {
  const [idToken, setIdToken] = useState<string | null>(() =>
    localStorage.getItem(TOKEN)
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(() =>
    localStorage.getItem(REFRESH_TOKEN)
  );
  const [expiration, setExpiration] = useState<Date | null>(() => {
    const expiration = localStorage.getItem(EXPIRATION);
    if (expiration == null) return null;
    return new Date(expiration);
  });

  const loggedIn = () =>
    idToken != null && refreshToken != null && expiration != null;

  const [loginPopupShown, setLoginPopupShown]  = useState(false);
  const [scrollPosition, scrollPositionSetter] = useState(0);
  const [alreadyLoginPopupShown, setAlreadyLoginPopupShown]  = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [forgotPasswordShown, setForgotPasswordShown] = useState(false);
  const [createAccountShown, setCreateAccountShown] = useState(false);
  const [verifyCreateAccountShown, setVerifyCreateAccountShown] = useState(false);
  const [ChangePasswordShown, setChangePasswordShown] = useState(false);
  const [succesfullyChangedShown, setSuccesfullyChangedShown] = useState(false);
  const [succesfullyCreatedShown, setSuccesfullyCreatedShown] = useState(false);
  const [verifyChangePasswordShown, setVerifyChangePasswordShown] = useState(false);
  const [email, emailSetter] = useState('');
  const [code, codeSetter] = useState('');

  const setEmail = (data : string) => {
    emailSetter(data);
  }
  const setScrollPosition = (data : number) => {
    scrollPositionSetter(data);
  }
  const setCode = (data : string) => {
    codeSetter(data);
  }
  const showLoginPopup = () => setLoginPopupShown(true);
  const hideLoginPopup = () => setLoginPopupShown(false);
  const showAlreadyLoginPopup = () => setAlreadyLoginPopupShown(true);
  const hideAlreadyLoginPopup = () =>{ 
    setAlreadyLoginPopupShown(false);
    window.scrollTo(0, scrollPosition);
  }
  const showForgotPassword = () => {
    hideLoginPopup();
    setForgotPasswordShown(true);
  }
  const loginFromForgotPassword = () => {
    setForgotPasswordShown(false);
    showLoginPopup();
  }

  const loginFromCreateAccount = () => {
    setCreateAccountShown(false);
    showLoginPopup();
  }
  const hideForgotPassword = () => setForgotPasswordShown(false);

  const showCreateAccount= () => {
    hideLoginPopup();
    setCreateAccountShown(true);
  }
  const hideCreateAccount = () => setCreateAccountShown(false);

  const showVerifyCreateAccount = () => {
    hideCreateAccount();
    setVerifyCreateAccountShown(true);
  }

  const hideVerifyCreateAccount = () => {
    setVerifyCreateAccountShown(false);
  }

  const createAccountFromVerify = () => {
    hideVerifyCreateAccount();
    showCreateAccount();
  }

  const showChangePassword = () => {
    hideVerifyChangePassword();
    setChangePasswordShown(true);
  }
  const hideChangePassword = () => {
    setChangePasswordShown(false);
  }
  const showSuccesfullyChanged = () => {
    hideChangePassword();
    setSuccesfullyChangedShown(true);
  }

  const hideSuccesfullyChanged = () => {
    setSuccesfullyChangedShown(false);
  }

  const showSuccessfullyCreated = () => {
    hideVerifyCreateAccount();
    setSuccesfullyCreatedShown(true);
  }

  const hideSuccessfullyCreated = () => {
    setSuccesfullyCreatedShown(false);
  }

  const showVerifyChangePassword = () => {
    hideForgotPassword();
    setVerifyChangePasswordShown(true);
  }

  const hideVerifyChangePassword = () => {
    setVerifyChangePasswordShown(false);
  }

  const forgotPasswordFromVerify = () => {
    hideVerifyChangePassword();
    setForgotPasswordShown(true);
  }


  const login = (data: TokenEndpointResponse) => {
    localStorage.setItem(TOKEN, data.idToken);
    setIdToken(data.idToken);

    localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
    setRefreshToken(data.refreshToken);

    localStorage.setItem(EXPIRATION, data.expiration.toISOString());
    setExpiration(data.expiration);
  };

  const logout = () => {
    localStorage.removeItem(TOKEN);
    setIdToken(null);

    localStorage.removeItem("refresh_token");
    setRefreshToken(null);

    localStorage.removeItem("expiration");
    setExpiration(null);
  };

  const getIdToken = async (): Promise<string | undefined> => {
    if (!loggedIn()) return undefined;

    if (expiration! < new Date()) {
      try {
        const response = await tokenFromRefreshToken(refreshToken!);
        login({ ...response, refreshToken: refreshToken! });
      } catch {
        logout();
      }
    }

    return idToken!;
  };

  const decodeJwt = () => {
    const idToken = localStorage.getItem(TOKEN);

    if (idToken == null) return undefined;

    const [_, payloadb64] = idToken.split(".");
    const payloadjson = atob(payloadb64);
    const payload = JSON.parse(payloadjson);

    return payload;
  }

  const getId = () => decodeJwt()?.sub;
  
  const getName = () => decodeJwt()?.given_name;

  return (
    <AuthContext.Provider
      value={{
        loggedIn: loggedIn(),
        loginPopupShown,
        alreadyLoginPopupShown,
        forgotPasswordShown,
        createAccountShown,
        verifyCreateAccountShown,
        ChangePasswordShown,
        succesfullyChangedShown,
        succesfullyCreatedShown,
        verifyChangePasswordShown,
        loginSuccess,
        name: getName(),
        email,
        code,
        scrollPosition,
        showLoginPopup,
        hideLoginPopup,
        showAlreadyLoginPopup,
        hideAlreadyLoginPopup,
        showForgotPassword, 
        hideForgotPassword,
        loginFromForgotPassword,
        loginFromCreateAccount,
        showCreateAccount,
        hideCreateAccount,
        showVerifyCreateAccount,
        hideVerifyCreateAccount,
        createAccountFromVerify,
        showChangePassword,
        hideChangePassword,
        showSuccesfullyChanged,
        hideSuccesfullyChanged,
        showSuccessfullyCreated,
        hideSuccessfullyCreated,
        showVerifyChangePassword, 
        hideVerifyChangePassword, 
        forgotPasswordFromVerify,
        login,
        logout,
        getIdToken,
        getId,
        setEmail,
        setCode,
        setScrollPosition
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
