import Button from "./button";

export type Props = {
  onBack?: () => void;
  submitText?: string;
  submitDisabled?: boolean;
  submitLoading?: boolean;
};

const FormActions = ({
  onBack,
  submitText,
  submitDisabled,
  submitLoading,
}: Props) => {
  return (
    <div className="flex justify-end mt-[2vh]">
      {onBack && <Button text="Back" type="tertiary" />}
      <button type="submit">
        <Button
          text={submitText ?? "Next"}
          disabled={submitDisabled}
          loading={submitLoading}
        />{" "}
      </button>
    </div>
  );
};

export default FormActions;
