import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { gql } from "../__generated__";
import { AddPaymentMethodForm } from "../components/addpaymentmethod";

import React from "react";
import { useLocation } from "react-router-dom";
import { Invoice } from "../components/invoice";
import { DatePickerForm } from "../components/datepickerform";
import { useQuery } from "@apollo/client";
import SpotCard from "../components/spotcard";
import { StripeContext } from "../context/StripeProvider";

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const GET_CHECKOUT_TOKEN =
  gql(`query GetCheckoutToken($spotId: String!, $start: String!, $end: String!) {
    getInvoice(spotId: $spotId, start: $start, end: $end) {
      token
    }
  }`);


const Checkout = () => {
  const stripe = useContext(StripeContext);

  const params = useQueryParams();
  const { spotId } = useParams();

  const [start, setStart] = useState<Date | null>(new Date(params.get("start") ?? ""))
  const [end, setEnd] = useState<Date | null>(new Date(params.get("end") ?? ""))

  const { loading, error, data } = useQuery(GET_CHECKOUT_TOKEN, {
    variables: {
      spotId: spotId ?? "",
      start: start?.toISOString() ?? "",
      end: end?.toISOString() ?? "",
    },
    skip: !spotId || !start || !end,
  })

  const token = data?.getInvoice?.token;

  if(!spotId) return; // check for start and end too. redirct if invalid

  return (
    <div className="container mx-auto flex flex-col md:flex-row gap-8 mt-10 p-5">
      <div className="flex-1">
        <h1 className="font-bold text-3xl mb-2">Choose how to pay</h1>
        <p>All transactions are secure and encrypted</p>
        <p className="mb-10">If you are having issues entering payment on mobile, try on a computer.</p>
        <AddPaymentMethodForm redirectUrl={`${process.env["REACT_APP_SITE_URL"]}/checkout/${spotId}/finalize?token=${token}&key=${stripe.apiKey}`} disabled={token == null || loading} />
      </div>
      <div className="flex-1 rounded-lg bg-bms-fill-regular p-4 py-8 shadow-lg">
        <p>Reserving</p>
        {/*<SpotCard spot={{}} />*/}
        <div className="my-5">
          <DatePickerForm id={spotId} start={start} onStartChange={setStart} end={end} onEndChange={setEnd} />
        </div>
        { start && end && <Invoice spotId={spotId} start={start} end={end} /> }
      </div>
    </div>
  );
};

export default Checkout;
