const Pickup = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M130.743 86.2067C130.743 87.5367 103.548 88.5867 70.0176 88.5867C36.4526 88.5867 9.25757 87.5367 9.25757 86.2067C9.25757 84.8767 36.4526 83.8267 70.0176 83.8267C103.548 83.8267 130.743 84.8767 130.743 86.2067Z"
        fill="#67A9F1"
      />
      <path
        d="M23.7126 68.3916H112.228V76.3716L110.443 79.5566L107.818 77.5966H96.1626L93.9226 79.8716L91.8226 77.5966H44.0476L40.9676 79.8716L38.7976 77.5616H26.8276L24.4826 79.8716L23.7126 68.3916Z"
        fill="#0D1F27"
      />
      <path
        d="M39.6377 79.4868C39.6377 83.3018 36.5577 86.3818 32.7427 86.3818C28.9277 86.3818 25.8477 83.3018 25.8477 79.4868C25.8477 75.6718 28.9277 72.5918 32.7427 72.5918C36.5577 72.5918 39.6377 75.6718 39.6377 79.4868Z"
        fill="#2E363D"
      />
      <path
        d="M37.1528 79.4867C37.1528 81.9367 35.1928 83.8967 32.7428 83.8967C30.2928 83.8967 28.3328 81.9367 28.3328 79.4867C28.3328 77.0367 30.2928 75.0767 32.7428 75.0767C35.1928 75.0767 37.1528 77.0367 37.1528 79.4867Z"
        fill="#93A5A5"
      />
      <path
        d="M36.9076 80.9569L35.0176 80.4319L36.6976 81.377C36.6742 81.4703 36.6276 81.5636 36.5576 81.6569L33.8976 80.152C33.7809 80.362 33.6176 80.5253 33.4076 80.642L34.9126 83.302C34.8192 83.372 34.7259 83.4303 34.6326 83.477L33.6876 81.762L34.2126 83.652C34.1192 83.6753 34.0142 83.6986 33.8976 83.722L33.0926 80.7819C32.9759 80.8286 32.8592 80.852 32.7426 80.852C32.6259 80.852 32.5092 80.8286 32.3926 80.7819L31.5876 83.722C31.4709 83.6986 31.3659 83.6753 31.2726 83.652L31.7976 81.762L30.8526 83.477C30.7592 83.4303 30.6659 83.372 30.5726 83.302L32.0776 80.642C31.8676 80.5253 31.7042 80.362 31.5876 80.152L28.9276 81.6569C28.8576 81.5636 28.7992 81.4703 28.7526 81.377L30.4676 80.4319L28.5776 80.9569C28.5542 80.8636 28.5309 80.7586 28.5076 80.642L31.4476 79.837C31.4009 79.7203 31.3776 79.6036 31.3776 79.487C31.3776 79.3703 31.4009 79.2536 31.4476 79.137L28.5076 78.3319C28.5309 78.2153 28.5542 78.1103 28.5776 78.017L30.4676 78.542L28.7526 77.597C28.7992 77.5036 28.8576 77.4103 28.9276 77.317L31.5876 78.822C31.7042 78.612 31.8676 78.4486 32.0776 78.3319L30.5726 75.672C30.6659 75.602 30.7592 75.5436 30.8526 75.497L31.7976 77.212L31.2726 75.322C31.3659 75.2986 31.4709 75.2753 31.5876 75.252L32.3926 78.192C32.5092 78.1686 32.6259 78.1453 32.7426 78.122C32.8592 78.1453 32.9759 78.1686 33.0926 78.192L33.8976 75.252C34.0142 75.2753 34.1192 75.2986 34.2126 75.322L33.6876 77.212L34.6326 75.497C34.7259 75.5436 34.8192 75.602 34.9126 75.672L33.4076 78.3319C33.6176 78.4486 33.7809 78.612 33.8976 78.822L36.5576 77.317C36.6276 77.4103 36.6742 77.5036 36.6976 77.597L35.0176 78.542L36.9076 78.017C36.9309 78.1103 36.9542 78.2153 36.9776 78.3319L34.0376 79.137C34.0609 79.2536 34.0842 79.3703 34.1076 79.487C34.0842 79.6036 34.0609 79.7203 34.0376 79.837L36.9776 80.642C36.9542 80.7586 36.9309 80.8636 36.9076 80.9569Z"
        fill="#E2E5E7"
      />
      <path
        d="M109.043 79.4868C109.043 83.3018 105.963 86.3818 102.183 86.3818C98.3676 86.3818 95.2876 83.3018 95.2876 79.4868C95.2876 75.6718 98.3676 72.5918 102.183 72.5918C105.963 72.5918 109.043 75.6718 109.043 79.4868Z"
        fill="#2E363D"
      />
      <path
        d="M106.593 79.4867C106.593 81.9367 104.598 83.8967 102.183 83.8967C99.7327 83.8967 97.7727 81.9367 97.7727 79.4867C97.7727 77.0367 99.7327 75.0767 102.183 75.0767C104.598 75.0767 106.593 77.0367 106.593 79.4867Z"
        fill="#93A5A5"
      />
      <path
        d="M106.313 80.9569L104.423 80.4319L106.138 81.377C106.091 81.4703 106.044 81.5636 105.998 81.6569L103.338 80.152C103.221 80.362 103.058 80.5253 102.848 80.642L104.353 83.302C104.259 83.372 104.166 83.4303 104.073 83.477L103.093 81.762L103.653 83.652C103.536 83.6753 103.431 83.6986 103.338 83.722L102.498 80.7819C102.404 80.8286 102.299 80.852 102.183 80.852C102.066 80.852 101.949 80.8286 101.833 80.7819L100.993 83.722C100.899 83.6986 100.806 83.6753 100.713 83.652L101.238 81.762L100.258 83.477C100.164 83.4303 100.071 83.372 99.9776 83.302L101.483 80.642C101.296 80.5253 101.144 80.362 101.028 80.152L98.3326 81.6569C98.2859 81.5636 98.2393 81.4703 98.1926 81.377L99.9076 80.4319L98.0176 80.9569C97.9943 80.8636 97.9593 80.7586 97.9126 80.642L100.853 79.837C100.829 79.7203 100.818 79.6036 100.818 79.487C100.818 79.3703 100.829 79.2536 100.853 79.137L97.9126 78.3319C97.9593 78.2153 97.9943 78.1103 98.0176 78.017L99.9076 78.542L98.1926 77.597C98.2393 77.5036 98.2859 77.4103 98.3326 77.317L101.028 78.822C101.144 78.612 101.296 78.4486 101.483 78.3319L99.9776 75.672C100.071 75.602 100.164 75.5436 100.258 75.497L101.238 77.212L100.713 75.322C100.806 75.2986 100.899 75.2753 100.993 75.252L101.833 78.192C101.949 78.1686 102.066 78.1453 102.183 78.122C102.299 78.1453 102.404 78.1686 102.498 78.192L103.338 75.252C103.431 75.2753 103.536 75.2986 103.653 75.322L103.093 77.212L104.073 75.497C104.166 75.5436 104.259 75.602 104.353 75.672L102.848 78.3319C103.058 78.4486 103.221 78.612 103.338 78.822L105.998 77.317C106.044 77.4103 106.091 77.5036 106.138 77.597L104.423 78.542L106.313 78.017C106.359 78.1103 106.394 78.2153 106.418 78.3319L103.478 79.137C103.501 79.2536 103.513 79.3703 103.513 79.487C103.513 79.6036 103.501 79.7203 103.478 79.837L106.418 80.642C106.394 80.7586 106.359 80.8636 106.313 80.9569Z"
        fill="#E2E5E7"
      />
      <path
        d="M126.438 73.4666V74.3065C126.438 74.3065 126.753 76.3716 121.993 77.2116L110.443 79.5565C110.443 79.5332 110.443 79.5099 110.443 79.4866C110.443 74.9366 106.733 71.2265 102.183 71.2265C97.5976 71.2265 93.9226 74.9366 93.9226 79.4866C93.9226 79.6032 93.9226 79.7316 93.9226 79.8716H40.9676C40.991 79.7316 41.0026 79.6032 41.0026 79.4866C41.0026 74.9366 37.2926 71.2265 32.7426 71.2265C28.1926 71.2265 24.4826 74.9366 24.4826 79.4866C24.4826 79.6032 24.4826 79.7316 24.4826 79.8716H18.0076C18.0076 79.8716 15.9426 79.9415 15.9426 77.4565V74.7265C15.9426 72.8015 17.4126 72.9416 17.4126 72.9416V69.8616C17.8676 67.5516 19.5126 64.0515 25.0776 63.4565L41.7726 62.1265L53.0076 53.9015C53.0076 53.9015 56.8926 51.4166 60.2876 51.4166H79.8526C79.8526 51.4166 82.6876 51.2415 82.6876 53.3765V61.7065H123.043C123.043 61.7065 124.863 62.0916 124.863 64.0166V71.3315C124.863 71.3315 126.123 71.1916 126.333 72.3466C126.403 72.6499 126.438 73.0232 126.438 73.4666Z"
        fill="#797C7E"
      />
      <path
        d="M15.9426 77.4567V74.8667H25.8826C25.0076 76.1967 24.4826 77.7717 24.4826 79.4867C24.4826 79.6034 24.4826 79.7317 24.4826 79.8717H18.0076C18.0076 79.8717 15.9426 79.9417 15.9426 77.4567Z"
        fill="#5C6264"
      />
      <path
        d="M39.6025 74.8667H95.3225C94.4125 76.1967 93.9225 77.7717 93.9225 79.4867C93.9225 79.6034 93.9225 79.7317 93.9225 79.8717H40.9675C40.9909 79.7317 41.0025 79.6034 41.0025 79.4867C41.0025 77.7717 40.4775 76.1967 39.6025 74.8667Z"
        fill="#5C6264"
      />
      <path
        d="M110.443 79.5565C110.443 79.5331 110.443 79.5098 110.443 79.4865C110.443 77.7365 109.882 76.1265 108.938 74.7615L123.673 73.5015C123.673 73.5015 125.598 73.2915 126.368 72.5215C126.414 72.7782 126.438 73.0932 126.438 73.4665V74.3065C126.438 74.3065 126.753 76.3715 121.993 77.2115L110.443 79.5565Z"
        fill="#5C6264"
      />
      <path
        d="M17.4126 72.4164V69.8614C17.5876 68.9164 18.0076 67.7614 18.7776 66.7114H22.1376C23.0826 66.7114 23.8876 67.5164 23.8876 68.4614V70.6664C23.8876 71.6114 23.0826 72.4164 22.1376 72.4164H17.4126Z"
        fill="#4A5052"
      />
      <path
        d="M18.1826 78.8215H16.2226C16.0476 78.4715 15.9426 78.0515 15.9426 77.4565V75.8115H18.1826C19.0226 75.8115 19.6876 76.4765 19.6876 77.3165C19.6876 78.1565 19.0226 78.8215 18.1826 78.8215Z"
        fill="#0D1F27"
      />
      <path
        d="M22.6626 69.6514V69.6864C22.6626 70.6314 21.8576 71.4364 20.9126 71.4364H17.4126V69.8614C17.5176 69.2664 17.7276 68.5664 18.0776 67.9014H20.9126C21.8576 67.9014 22.6626 68.6714 22.6626 69.6514Z"
        fill="#FFDE17"
      />
      <path
        d="M22.1377 76.8616C24.1677 68.0066 32.7777 69.4766 32.7777 69.4766C23.7827 69.4766 23.4677 77.0366 23.4677 77.0366L22.1377 76.8616Z"
        fill="#5C6264"
      />
      <path
        d="M120.418 64.9266H84.8575C84.5775 64.9266 84.3325 64.6816 84.3325 64.4016C84.3325 64.1216 84.5775 63.9116 84.8575 63.9116H120.418C120.698 63.9116 120.943 64.1216 120.943 64.4016C120.943 64.6816 120.698 64.9266 120.418 64.9266Z"
        fill="#5C6264"
      />
      <path
        d="M42.0176 63.6313H82.6876V73.7813H41.2826C41.2826 73.7813 40.9326 68.4963 42.0176 63.6313Z"
        fill="url(#paint0_linear_51_5229)"
      />
      <path
        d="M78.3477 53.1313C79.2927 53.1313 80.0977 53.9363 80.0977 54.8813V60.7964C80.0977 61.7414 79.2927 62.5464 78.3477 62.5464H73.9727C72.9927 62.5464 72.2227 61.7414 72.2227 60.7964V54.8813C72.2227 53.9363 72.9927 53.1313 73.9727 53.1313H78.3477Z"
        fill="#0D1F27"
      />
      <path
        d="M67.6377 62.5463H44.1877L53.9877 55.3363C55.0027 54.7063 57.9777 53.1663 60.2877 53.1663H67.6377C67.6377 53.1663 69.9127 53.0613 69.9127 55.3013V60.4113C69.9127 60.4113 70.1577 62.5463 67.6377 62.5463Z"
        fill="#0D1F27"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.1726 78.7515H42.0176V78.6815C40.0576 71.6465 41.9826 63.0715 42.0176 62.9665V62.9315H42.0526L53.4976 54.5315C53.9526 54.2165 57.4176 52.1865 60.2876 52.1865H71.1726V78.7515ZM42.1576 78.6115H70.9976V52.3965H60.2876C57.4176 52.3965 53.9526 54.4615 53.5676 54.6715L42.1576 63.0715C42.0526 63.6315 40.3026 71.8215 42.1576 78.6115Z"
        fill="#4A5052"
      />
      <path
        d="M50.0675 61.6716C50.0675 62.7566 48.6675 63.6316 46.9525 63.6316C45.2725 63.6316 44.9575 62.7566 44.9575 61.6716C44.9575 60.6216 45.2725 59.7466 46.9525 59.7466C48.6675 59.7466 50.0675 60.6216 50.0675 61.6716Z"
        fill="url(#paint1_radial_51_5229)"
      />
      <path
        d="M69.3176 65.5566H65.7476C65.3626 65.5566 65.0476 65.2066 65.0476 64.8216C65.0476 64.4366 65.3626 64.1216 65.7476 64.1216H69.3176C69.7376 64.1216 70.0526 64.4366 70.0526 64.8216C70.0526 65.2066 69.7376 65.5566 69.3176 65.5566Z"
        fill="#797C7E"
      />
      <path
        d="M70.0526 64.8564C70.0176 65.2414 69.7026 65.5564 69.3176 65.5564H65.7476C65.3626 65.5564 65.0476 65.2414 65.0476 64.8564H70.0526Z"
        fill="#4A5052"
      />
      <path
        d="M80.0977 59.4315V60.7965C80.0977 61.5665 79.5377 62.2315 78.8027 62.4415L72.2227 55.8615V54.8815C72.2227 53.9715 72.9227 53.2365 73.8327 53.1665L80.0977 59.4315Z"
        fill="#223139"
      />
      <path
        d="M58.3626 53.4468L67.4626 62.5468H60.8126L53.7776 55.4768L53.9876 55.3368C54.7226 54.8818 56.5426 53.9018 58.3626 53.4468Z"
        fill="#223139"
      />
      <path
        d="M40.5127 73.1867C37.6427 64.4367 27.1077 64.7867 27.1077 64.7867L40.9677 63.5967C39.8477 70.2817 40.5127 73.1867 40.5127 73.1867Z"
        fill="url(#paint2_linear_51_5229)"
      />
      <path
        d="M83.1777 65.4863H122.518V73.0463H108.448C108.448 73.0463 106.103 70.2113 101.588 70.2113C97.0727 70.2113 94.9377 73.8863 94.9377 73.8863H83.1777V65.4863Z"
        fill="url(#paint3_linear_51_5229)"
      />
      <path
        d="M111.983 77.0366C111.983 77.0366 111.703 69.4766 102.673 69.4766C102.673 69.4766 111.318 68.0066 113.348 76.8616L111.983 77.0366Z"
        fill="#5C6264"
      />
      <path
        d="M124.268 63.3868H83.1426V62.0567H122.028C123.568 61.9867 124.268 63.3868 124.268 63.3868Z"
        fill="url(#paint4_linear_51_5229)"
      />
      <path
        d="M122.062 68.8815V65.6265C122.062 64.6465 122.832 63.8765 123.812 63.8765H124.828C124.851 63.9231 124.862 63.9698 124.862 64.0165V70.6315H123.812C122.832 70.6315 122.062 69.8615 122.062 68.8815Z"
        fill="#EE3837"
      />
      <path
        d="M82.6875 61.7065H83.3525V79.8716H82.6875V61.7065Z"
        fill="#4A5052"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_5229"
          x1="63.1926"
          y1="65.1013"
          x2="63.1926"
          y2="70.2113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8F8E90" />
          <stop offset="1" stop-color="#797C7E" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_51_5229"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48.1775 61.0066) scale(3.22 3.21999)"
        >
          <stop stop-color="#969597" />
          <stop offset="1" stop-color="#616465" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_51_5229"
          x1="27.1077"
          y1="63.5967"
          x2="40.9677"
          y2="63.5967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6264" />
          <stop offset="1" stop-color="#797C7E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_51_5229"
          x1="103.758"
          y1="65.7313"
          x2="103.758"
          y2="69.7563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8F8E90" />
          <stop offset="1" stop-color="#797C7E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_51_5229"
          x1="103.723"
          y1="62.0917"
          x2="103.723"
          y2="62.7217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8F8E90" />
          <stop offset="1" stop-color="#797C7E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Pickup;
