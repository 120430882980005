import AmenityCheckbox from "../../components/amenity-checkbox/amenity-checkbox";
import FormActions from "../../components/formactions";
import Textfield from "../../components/textfield/textfield";
import { Controller, useForm } from "react-hook-form";
import {
  FaWarehouse,
  FaTicketAlt,
  FaVideo,
  FaPlug,
  FaFootballBall,
  FaRestroom,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { FormContext } from "./FormContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ImageUploader from "../../components/image-uploader";

const schema = yup.object({
  nickname: yup.string().required(),
  description: yup.string().required(),
  sheltered: yup.boolean(),
  parkingPass: yup.boolean(),
  securityCameras: yup.boolean(),
  evCharging: yup.boolean(),
  tailgatingPermitted: yup.boolean(),
  restroomAccess: yup.boolean(),
  image: yup.string(),
});

interface FormValues extends yup.InferType<typeof schema> {}

export const Details = () => {
  let [formData, setFormData] = useContext(FormContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      nickname: formData.nickname,
      description: formData.description,
      sheltered: formData.sheltered,
      parkingPass: formData.parkingPass,
      securityCameras: formData.securityCameras,
      evCharging: formData.evCharging,
      tailgatingPermitted: formData.tailgatingPermitted,
      restroomAccess: formData.restroomAccess,
      image: formData.image,
    },
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    console.log(data);

    setFormData({
      ...formData,
      nickname: data.nickname,
      description: data.description,
      sheltered: data.sheltered,
      parkingPass: data.parkingPass,
      securityCameras: data.securityCameras,
      evCharging: data.evCharging,
      tailgatingPermitted: data.tailgatingPermitted,
      restroomAccess: data.restroomAccess,
      image: data.image,
    });

    navigate("../requirements");
  });

  return (
    
    <form onSubmit={onSubmit}>
      <div className = "text-center text-2xl font-bold mt-[2vh]">
        Additional Details
      </div>
      <div>
        <Textfield
          {...register("nickname", { required: true })}
          label={"Nickname"}
          validationMessage={errors.nickname?.message}
        />
        <Textfield
          {...register("description", { required: true })}
          label={"Description"}
          validationMessage={errors.description?.message}
        />
        <div className="mt-[4vh] ml-12 mr-12">
          <p className="font-semibold mb-2">Photo</p>
          <Controller
            control={control}
            name="image"
            render={({ field: { onChange, value } }) => (
              <ImageUploader onChange={onChange} defaultImage={value} />
            )}
          />
        </div>
        <div className = "ml-12 mr-12">
          <p className="font-semibold mb-2 mt-[2vh]">Ammenities</p>
          <AmenityCheckbox
            {...register("sheltered")}
            text={"Sheltered"}
            icon={<FaWarehouse />}
          />
          <AmenityCheckbox
            {...register("parkingPass")}
            text={"Parking Pass"}
            icon={<FaTicketAlt />}
          />
          <AmenityCheckbox
            {...register("securityCameras")}
            text={"Security Cameras"}
            icon={<FaVideo />}
          />
          <AmenityCheckbox
            {...register("evCharging")}
            text={"EV Charging"}
            icon={<FaPlug />}
          />
          <AmenityCheckbox
            {...register("tailgatingPermitted")}
            text={"Tailgating Permitted"}
            icon={<FaFootballBall />}
          />
          <AmenityCheckbox
            {...register("restroomAccess")}
            text={"Restroom Access"}
            icon={<FaRestroom />}
          />
        </div>
        <FormActions submitDisabled={!isValid} />
      </div>
    </form>
  );
};

const Uploader = () => {};
