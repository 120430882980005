import { SendMessageModal } from "./sendmessagemodal";
import { useState } from "react";

export type Props = {
  name: string;
  id: string;
};

const Profile = ({ name, id }: Props) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div>
        {name}
        <br />
        <button className="text-bmsblue" onClick={() => setVisible(true)}>
          Message
        </button>
      </div>
      {visible && (
        <SendMessageModal
          recipientId={id}
          showModal={true}
          onSent={() => {
            setVisible(false);
            alert(
              "Your message has been sent. You can view it on the Messages page"
            );
          }}
        />
      )}
    </>
  );
};

export default Profile;
