const Lot = () => {
  return (
    <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M125.74 66.1494L46.7056 111.782L6.08667 88.3532L85.121 42.7202L125.74 66.1494Z"
        fill="#8AA451"
      />
      <path
        d="M133.913 71.9394L54.8788 117.572L46.6831 112.839L54.3729 108.399L27.2336 92.7266L90.9333 55.9526L118.072 71.6246L125.74 67.1951L133.913 71.9394Z"
        fill="#666666"
      />
      <path
        d="M125.74 66.1382V67.195L118.073 71.602V70.5677L125.74 66.1382Z"
        fill="#DBDBDB"
      />
      <path
        d="M118.073 71.6023L90.9333 56.054V54.896L118.073 70.5679V71.6023Z"
        fill="#BABABA"
      />
      <path
        d="M66.0988 95.234L64.6372 96.0772L43.0405 83.6093L44.502 82.7661L66.0988 95.234Z"
        fill="#FEFEFE"
      />
      <path
        d="M67.6165 94.3008L69.078 95.144L64.4911 97.7972L63.0295 96.9428L67.6165 94.3008Z"
        fill="#FEFEFE"
      />
      <path
        d="M81.411 86.3971L79.9495 87.2403L58.3528 74.7612L59.8143 73.918L81.411 86.3971Z"
        fill="#FEFEFE"
      />
      <path
        d="M82.929 85.4639L84.3905 86.307L79.8036 88.949L78.342 88.1058L82.929 85.4639Z"
        fill="#FEFEFE"
      />
      <path
        d="M96.723 77.5494L95.2615 78.3926L73.6648 65.9247L75.1263 65.0815L96.723 77.5494Z"
        fill="#FEFEFE"
      />
      <path
        d="M98.2295 76.6274L99.7022 77.4706L95.1153 80.1126L93.6538 79.2694L98.2295 76.6274Z"
        fill="#FEFEFE"
      />
      <path
        d="M23.5798 91.7372L91.0344 52.7935L118.072 68.398L123.862 65.0589L125.74 66.1382L118.072 70.5677L90.9332 54.8958L27.2336 91.6698L54.3729 107.342L46.7055 111.771L44.828 110.692L50.6179 107.342L23.5798 91.7372Z"
        fill="white"
      />
      <path
        d="M90.9333 56.054L28.1443 92.1984L27.2336 91.67L90.9333 54.896V56.054Z"
        fill="#DBDBDB"
      />
      <path
        d="M54.3729 107.342V108.399L46.7056 112.828V111.771L54.3729 107.342Z"
        fill="#DBDBDB"
      />
      <path
        d="M46.7056 111.772V112.862L44.8281 111.772V110.692L46.7056 111.772Z"
        fill="#BABABA"
      />
      <path
        d="M44.8281 111.772L6.08667 89.4215V88.3535L44.8281 110.692V111.772Z"
        fill="#617F3C"
      />
      <path
        d="M133.913 71.9395V74.2891L54.8789 119.776V117.572L133.913 71.9395Z"
        fill="#545454"
      />
      <path
        d="M54.8789 119.776L44.8281 114.009V111.771L54.8789 117.573V119.776Z"
        fill="#404040"
      />
      <path
        d="M44.8281 111.771V114.009L6.08667 91.6024V89.4214L44.8281 111.771Z"
        fill="#493B34"
      />
      <path
        d="M122.783 51.2532L115.419 55.5028V47.0035L122.783 42.7539V51.2532Z"
        fill="#1354A7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120.872 47.1052C120.872 47.6748 120.718 48.1995 120.411 48.6791C120.104 49.1588 119.665 49.5635 119.096 49.8933L118.68 50.1294V52.0181L117.713 52.569V47.2851L119.174 46.4419C119.729 46.1196 120.152 46.0147 120.445 46.1271C120.73 46.232 120.872 46.5581 120.872 47.1052ZM118.68 49.2188L119.006 49.0277C119.298 48.8553 119.519 48.6604 119.669 48.4431C119.819 48.2182 119.894 47.9746 119.894 47.7123C119.894 47.45 119.83 47.2926 119.703 47.2401C119.583 47.1876 119.388 47.2364 119.118 47.3863L118.68 47.6448V49.2188Z"
        fill="white"
      />
      <path
        d="M119.422 66.5319C119.253 66.633 118.961 66.633 118.781 66.5319C118.691 66.4757 118.657 66.4082 118.657 66.3407V53.6368L119.557 53.1084V66.3407C119.557 66.4082 119.512 66.4757 119.422 66.5319Z"
        fill="#D0D0D0"
      />
      <path
        d="M115.419 55.5029L115.06 55.2893V46.79L115.419 47.0036V55.5029Z"
        fill="#0F4180"
      />
      <path
        d="M122.783 42.7541L115.419 47.0038L115.06 46.7902L122.423 42.5405L122.783 42.7541Z"
        fill="#3876C7"
      />
      <path
        d="M36.4699 36.826L35.3788 38.1269L38.9039 41.8199L36.9315 42.6172L42.387 45.7646L38.6101 46.8977L43.6459 51.2201L39.8271 52.6469L43.8138 59.6971L41.0441 60.2007L47.9684 67.2509L44.9049 68.6357L51.7872 70.734L41.212 73.8814L27.2375 74.8466L19.0962 73.2939L17.7113 72.6224L12.2139 72.7064L19.4319 67.2509L14.0603 64.7749L22.1597 58.648L16.5363 56.8435L22.7891 51.7656L20.1873 50.0451L23.0829 47.6111L25.6008 44.002H22.8311L27.867 39.176L28.3705 37.6653L27.1535 37.9171L31.8956 31.916L36.4699 36.826Z"
        fill="#477D50"
      />
      <path
        d="M33.2383 79.1272H28.748L29.7133 73.3359H32.7767L32.8187 75.8958L33.2383 79.1272Z"
        fill="#493B34"
      />
      <path
        opacity="0.5"
        d="M19.0963 73.2938L18.9285 73.2518L39.8272 69.391L35.3789 66.4534L31.0565 60.6622L37.0575 58.8157L38.4843 57.3469L31.0145 52.6468L33.7003 47.4851L29.9234 43.876L33.3646 42.7849L33.7003 38.7562L30.469 37.5393L32.6092 36.2803L32.2315 32.4614L36.47 36.8258L35.3789 38.1268L38.904 41.8197L36.9316 42.6171L39.2817 45.7645L38.6102 46.8975L42.7228 51.5557L39.8272 52.6468L43.8139 59.697L41.0442 60.2006L47.9685 67.2507L44.905 68.6356L51.7874 70.7339L41.2121 73.8813L27.2376 74.8465L19.0963 73.2938Z"
        fill="#364A31"
      />
      <g opacity="0.2">
        <path
          d="M23.6704 59.9908L31.0563 60.6623L14.0603 64.7749L22.1596 58.6479L23.6704 59.9908Z"
          fill="#E5E884"
        />
        <path
          d="M25.6009 52.2692L22.1597 58.648L16.5364 56.8435L22.7892 51.7656L25.6009 52.2692Z"
          fill="#E5E884"
        />
        <path
          d="M23.0829 47.611L24.5097 45.5967L28.6643 45.7645L21.1944 50.7164L20.1873 50.045L23.0829 47.611Z"
          fill="#E5E884"
        />
        <path
          d="M26.4821 67.6706L12.2139 72.7065L19.4319 67.251L26.4821 67.6706Z"
          fill="#E5E884"
        />
        <path
          d="M30.133 38.8403L23.6704 44.0021H22.8311L27.8669 39.1761L27.9928 38.8403H30.133Z"
          fill="#E5E884"
        />
        <path
          d="M27.1536 37.9171L31.8957 31.916L30.5108 35.6929L27.9089 37.7912L27.1536 37.9171Z"
          fill="#E5E884"
        />
      </g>
      <path
        opacity="0.5"
        d="M28.748 79.1272L29.7133 73.3359H30.1329L29.6713 79.1272H28.748Z"
        fill="#A86F41"
      />
      <g opacity="0.2">
        <path
          d="M24.7615 51.3879L26.4401 50.0869V51.178L24.7615 51.3879Z"
          fill="#E5E884"
        />
        <path
          d="M33.4902 50.7164L35.1689 49.4155V50.5486L33.4902 50.7164Z"
          fill="#E5E884"
        />
        <path
          d="M29.8813 42.9532L29 43.5407V42.3657L29.8813 42.9532Z"
          fill="#E5E884"
        />
        <path
          d="M25.6008 55.8784V57.3472H23.6704L25.6008 55.8784Z"
          fill="#E5E884"
        />
        <path
          d="M34.9172 35.9028V37.4136H32.9868L34.9172 35.9028Z"
          fill="#E5E884"
        />
        <path
          d="M39.6592 53.4863V54.9551H37.7708L39.6592 53.4863Z"
          fill="#E5E884"
        />
        <path
          d="M21.0266 66.0759L21.9079 64.8589L23.7963 66.4955L21.0266 66.0759Z"
          fill="#E5E884"
        />
        <path
          d="M24.9713 69.9785L23.9221 71.6152H22.4114L24.9713 69.9785Z"
          fill="#E5E884"
        />
        <path
          d="M28.0767 43.5825L27.0695 44.4218L26.2302 43.5825H28.0767Z"
          fill="#E5E884"
        />
        <path
          d="M28.4126 57.2632L30.5109 58.1025L28.8323 58.5221L28.4126 57.2632Z"
          fill="#E5E884"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M45.1568 68.2581L43.3103 67.125H45.1568V68.2581Z"
          fill="#364A31"
        />
        <path
          d="M35.7986 62.8445L33.9521 61.7114H35.7986V62.8445Z"
          fill="#364A31"
        />
        <path
          d="M39.6172 73.0003L37.7708 71.8672H39.6172V73.0003Z"
          fill="#364A31"
        />
        <path
          d="M39.8272 60.1167L37.561 59.1935L39.8272 58.6479V60.1167Z"
          fill="#364A31"
        />
        <path
          d="M33.2805 52.647L35.3788 53.2345L33.7002 54.1997L33.2805 52.647Z"
          fill="#364A31"
        />
        <path
          d="M34.8752 39.4277L36.9315 39.9733L35.2949 40.9385L34.8752 39.4277Z"
          fill="#364A31"
        />
        <path
          d="M37.0154 49.2059L35.9243 48.1567H37.0154V49.2059Z"
          fill="#364A31"
        />
      </g>
      <path
        d="M92.8477 43.6975C92.8477 45.0347 91.7568 46.1255 90.4197 46.1255C89.6455 46.1255 88.9769 45.7736 88.5547 45.2458C87.5694 45.7385 86.4786 46.02 85.3173 45.9848C83.6283 45.9496 82.1152 45.2458 80.954 44.1902C79.8279 44.8587 78.5259 44.9643 77.3647 44.5772C76.7313 46.8293 74.6904 48.4831 72.2272 48.4831C69.5177 48.4831 67.2305 46.407 66.9138 43.7327C66.2804 43.6623 65.647 43.3808 65.1543 42.853C64.2394 41.8677 64.1339 40.425 64.8376 39.3693C64.7438 38.8767 64.6969 38.3723 64.6969 37.8562C64.8024 34.443 67.336 31.6631 70.5382 31.2056C70.5382 31.2056 70.5382 31.1939 70.5382 31.1704C69.7288 30.0092 69.2362 28.5313 69.3066 26.983C69.4121 23.1474 72.5087 20.1212 76.2387 20.2268C79.7576 20.3324 82.5726 23.1826 82.8189 26.7015C83.5579 26.42 84.4024 26.2792 85.247 26.3144C88.9066 26.42 91.7568 29.411 91.8272 33.1058C93.3403 34.0559 94.396 35.7449 94.396 37.6803C94.396 39.4749 93.5163 41.0584 92.1791 42.0437C92.6013 42.4659 92.8477 43.0641 92.8477 43.6975Z"
        fill="#8AA451"
      />
      <path
        d="M88.1675 43.381C88.1675 43.381 84.5079 48.5889 80.1797 50.7354C80.2852 51.4743 80.3204 55.2747 80.3556 56.3655C80.3556 56.8933 80.4964 57.386 80.5668 57.8786H77.0831C77.2942 56.8582 77.5406 55.521 77.6813 54.3598C77.7517 53.7968 77.8221 50.9113 77.9628 49.1519C74.9718 47.0758 70.8547 41.6919 70.8547 41.6919C71.5585 42.5716 74.4791 44.7885 75.0774 45.4923C75.3589 45.2811 74.7607 43.3106 74.7255 41.7975C74.7255 41.7975 76.0626 44.5774 75.6404 45.9849C76.2738 46.5127 77.3294 47.2869 78.0684 47.674C78.2091 45.3867 78.3499 43.8032 78.4203 42.7124C78.4554 42.4661 76.1682 37.6101 76.2738 37.786C77.2239 39.1935 77.6461 39.4399 78.4555 41.0937C78.7018 41.6215 79.3 36.8711 79.3 36.8711C79.6519 37.1526 79.2648 40.4251 79.4056 41.1641C79.4759 41.6215 79.5111 42.1846 79.5463 42.7828C79.5815 43.4162 79.6519 45.0348 79.7222 45.7034C80.3204 45.5275 81.4817 43.944 82.9244 40.8122C82.9244 40.8122 81.8335 46.1257 79.8982 46.7942C79.9686 47.674 79.9686 48.2018 79.9334 48.8704C81.2354 48.7648 85.0357 46.4424 88.1675 43.381Z"
        fill="#493B34"
      />
      <path
        opacity="0.5"
        d="M74.2329 32.1205C76.2034 32.7187 76.6609 32.8947 76.2034 33.2114C74.9014 34.0911 74.4088 34.1615 72.016 33.6336C68.0749 32.7187 65.3302 37.5747 64.732 38.8767C64.7085 38.5483 64.6968 38.2081 64.6968 37.8562C64.8023 34.443 67.3359 31.6631 70.5381 31.2056C70.5381 31.2056 70.5381 31.1939 70.5381 31.1704C69.7287 30.0092 69.2361 28.5313 69.3065 26.983C69.412 23.1474 72.5086 20.1212 76.2386 20.2268C78.4907 20.2972 80.426 21.4584 81.5872 23.1826C79.5815 22.1974 78.3147 21.564 75.2181 22.2677C69.3065 23.6401 69.9399 30.7482 74.2329 32.1205Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M82.8189 26.7015C83.5579 26.42 84.4024 26.2792 85.2469 26.3144C87.0767 26.3496 88.6954 27.1237 89.8566 28.3553C89.3991 28.3553 87.7453 27.9683 87.0767 27.8979C85.1765 27.7571 83.0652 28.2498 81.9392 28.5313C80.039 29.0591 79.2297 28.9887 79.9335 28.0035C80.6724 27.0182 82.8189 26.7015 82.8189 26.7015Z"
        fill="#E5E884"
      />
      <g opacity="0.5">
        <path
          d="M92.1791 42.0435C92.6014 42.4658 92.8477 43.064 92.8477 43.6974C92.8477 45.0346 91.7569 46.1254 90.4197 46.1254C89.6456 46.1254 88.977 45.7735 88.5547 45.2457C87.5694 45.7383 86.4786 46.0198 85.3174 45.9847C83.6283 45.9495 82.1152 45.2457 80.954 44.1901C79.828 44.8586 78.526 44.9642 77.3648 44.5771C76.7314 46.8292 74.6904 48.483 72.2273 48.483C69.5177 48.483 67.2305 46.4069 66.9138 43.7326C66.2804 43.6622 65.647 43.3807 65.1544 42.8529C65.0136 42.6887 64.8963 42.5127 64.8025 42.325C65.1075 42.325 65.4828 42.325 65.9285 42.325C67.1953 42.2899 67.512 42.1491 67.512 42.1491C67.512 42.1491 69.7641 44.366 72.0161 43.7678C76.6962 42.501 76.2739 37.7857 76.2739 37.7857C76.2739 37.7857 78.4556 41.0935 81.0595 41.7269C83.2412 42.2195 85.81 42.0084 87.5342 40.9879C91.6513 38.4895 89.1529 34.1965 89.1529 34.1965C89.1529 34.1965 87.9917 33.2112 89.8567 32.6482C90.666 32.4019 91.1938 31.8389 91.5457 31.2759C91.7217 31.8741 91.8272 32.4723 91.8272 33.1057C93.3403 34.0558 94.396 35.7448 94.396 37.6802C94.396 39.4748 93.5163 41.0583 92.1791 42.0435Z"
          fill="#385A27"
        />
        <path
          d="M88.5195 38.4896C88.5195 39.299 87.8861 39.9675 87.0768 39.9675C86.2675 39.9675 85.5989 39.299 85.5989 38.4896C85.5989 37.6803 86.2675 37.0469 87.0768 37.0469C87.8861 37.0469 88.5195 37.6803 88.5195 38.4896Z"
          fill="#385A27"
        />
        <path
          d="M85.5635 39.9677C85.5635 40.4603 85.1412 40.8826 84.6134 40.8826C84.1208 40.8826 83.6985 40.4603 83.6985 39.9677C83.6985 39.4398 84.1208 39.0176 84.6134 39.0176C85.1412 39.0176 85.5635 39.4398 85.5635 39.9677Z"
          fill="#385A27"
        />
        <path
          d="M70.855 41.5155C70.855 42.0433 70.4327 42.4304 69.9401 42.4304C69.4123 42.4304 68.99 42.0433 68.99 41.5155C68.99 40.9877 69.4123 40.6006 69.9401 40.6006C70.4327 40.6006 70.855 40.9877 70.855 41.5155Z"
          fill="#385A27"
        />
        <path
          d="M87.9917 36.132C87.9917 36.519 87.675 36.8357 87.3232 36.8357C86.9361 36.8357 86.6194 36.519 86.6194 36.132C86.6194 35.7449 86.9361 35.4634 87.3232 35.4634C87.675 35.4634 87.9917 35.7449 87.9917 36.132Z"
          fill="#385A27"
        />
        <path
          d="M72.2271 39.8619C72.2271 40.249 71.9104 40.5657 71.5585 40.5657C71.1714 40.5657 70.8547 40.249 70.8547 39.8619C70.8547 39.51 71.1714 39.1934 71.5585 39.1934C71.9104 39.1934 72.2271 39.51 72.2271 39.8619Z"
          fill="#385A27"
        />
      </g>
      <path
        d="M90.7715 43.0995C90.7715 43.5921 90.3844 44.0144 89.8565 44.0144C89.3287 44.0144 88.9417 43.5921 88.9417 43.0995C88.9417 42.5717 89.3287 42.1494 89.8565 42.1494C90.3844 42.1494 90.7715 42.5717 90.7715 43.0995Z"
        fill="#87AD54"
      />
      <path
        d="M91.6513 41.586C91.6513 41.9379 91.3697 42.1842 91.053 42.1842C90.7012 42.1842 90.4548 41.9379 90.4548 41.586C90.4548 41.2693 90.7012 40.9878 91.053 40.9878C91.3697 40.9878 91.6513 41.2693 91.6513 41.586Z"
        fill="#87AD54"
      />
      <path
        opacity="0.5"
        d="M74.5144 25.3641C74.5144 25.892 74.0921 26.3142 73.5995 26.3142C73.0717 26.3142 72.6494 25.892 72.6494 25.3641C72.6494 24.8715 73.0717 24.4492 73.5995 24.4492C74.0921 24.4492 74.5144 24.8715 74.5144 25.3641Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M72.0511 35.4633C72.0511 36.3078 71.3474 36.9764 70.5028 36.9764C69.6935 36.9764 68.9897 36.3078 68.9897 35.4633C68.9897 34.6188 69.6935 33.9502 70.5028 33.9502C71.3474 33.9502 72.0511 34.6188 72.0511 35.4633Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M75.0069 23.6752C75.0069 23.9215 74.831 24.1326 74.5846 24.1326C74.3383 24.1326 74.1272 23.9215 74.1272 23.6752C74.1272 23.4289 74.3383 23.2529 74.5846 23.2529C74.831 23.2529 75.0069 23.4289 75.0069 23.6752Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M79.828 29.5517C79.828 29.798 79.6168 29.9739 79.3705 29.9739C79.1594 29.9739 78.9482 29.798 78.9482 29.5517C78.9482 29.3053 79.1594 29.1294 79.3705 29.1294C79.6168 29.1294 79.828 29.3053 79.828 29.5517Z"
        fill="#E5E884"
      />
      <path
        opacity="0.5"
        d="M78.2794 55.4153L77.822 57.8785H77.083C77.2941 56.8581 77.5405 55.5209 77.6812 54.3597C77.7516 53.7967 77.822 51.052 77.9275 49.2925L78.2794 49.2222V49.8556V55.4153Z"
        fill="#A86F41"
      />
    </svg>
  );
};

export default Lot;
