import { useContext } from "react";
import {
  MainContainer,
  ChatContainer,
  ConversationHeader,
  Avatar,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_MESSAGES = gql`
  query GetMessagesInThread($threadId: ID!) {
    messages(threadId: $threadId) {
      messages {
        id
        timestamp
        text
        type
        authorId
        threadId
      }
    }
  }
`;

const SEND_MESSAGE_IN_THREAD = gql`
  mutation SendMessage($threadId: ID!, $message: String!) {
    sendMessage(threadId: $threadId, message: $message) {
      code
      success
      message
      sentMessage {
        id
        timestamp
        text
        type
        authorId
        threadId
      }
    }
  }
`;

interface IMessage {
  id: string;
  timestamp: string;
  text: string;
  type: number;
  authorId: string;
  threadId: string;
}

interface IMessageList {
  messages: IMessage[];
}

interface SendMessageResponse {
  code: number;
  success: boolean;
  message: string;
  sentMessage: IMessage;
}

export const Thread = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { threadId } = useParams();

  const [sendMessage] = useMutation<
    { sendMessage: SendMessageResponse },
    { threadId: string; message: string }
  >(SEND_MESSAGE_IN_THREAD, {
    //update: (cache, { data: sendMessage })
    /*update(cache, { data }) {
      data.data.send
      cache.modify({
        fields: {
          messages
        }
      })
    }*/
  });

  const { loading, error, data, startPolling } = useQuery<
    { messages: IMessageList },
    { threadId: string }
  >(GET_MESSAGES, {
    variables: {
      threadId: threadId!,
    },
  });

  startPolling(500);

  const messages = data?.messages.messages;

  return (
    <div className="mx-auto max-w-3xl">
      <h1 className="text-3xl mt-7 font-bold">Messages</h1>
      <div className="lg:bg-white lg:drop-shadow-md lg:my-5 lg:rounded-xl lg:overflow-hidden">
        <div
          style={{
            height: "700px",
            overflow: "hidden",
          }}
        >
          <MainContainer>
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Back onClick={() => navigate(-1)} />
                {/*<Avatar name="Joe" />*/}
                <ConversationHeader.Content userName="Thread" />
              </ConversationHeader>

              <MessageList style={{ overflowAnchor: "none" }}>
                {(messages ?? []).map((message) => (
                  <Message
                    model={{
                      message: message.text,
                      sentTime: "just now",
                      sender: "Joe",
                      direction:
                        auth.getId() === message.authorId
                          ? "outgoing"
                          : "incoming",
                      position: "first",
                    }}
                  />
                ))}
              </MessageList>
              <MessageInput
                placeholder="Type message here"
                attachButton={false}
                onSend={(innerHtml, textContent, innerText, nodes) =>
                  sendMessage({
                    variables: {
                      threadId: threadId ?? "",
                      message: textContent,
                    },
                  })
                }
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </div>
  );
};
