import { ReactNode, useId, forwardRef } from "react";

export type Ref = HTMLInputElement;

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  text: string;
  icon: ReactNode;
}

/*export interface Props {
  text: string;
  checked: boolean;
  icon: ReactNode;
  name?: string;
  onChange: (checked: boolean) => void;
}*/

export default forwardRef<Ref, Props>(function AmenityCheckbox(props, ref) {
  const { text, icon, ...inputProps } = props;

  return (
    <label className="relative">
      <input
        {...inputProps}
        ref={ref}
        type="checkbox"
        className={"peer opacity-0 width-0 absolute"}
      />
      <div
        className={`border rounded-md px-3 py-1 inline-flex items-center space-x-2 cursor-pointer border-gray-500 text-gray-700 hover:text-gray-500 peer-focus:outline outline-2 outline-bmsblue peer-checked:border-bmsblue peer-checked:bg-bmsblue peer-checked:text-white peer-checked:hover:text-slate-200`}
      >
        {icon}
        <p>{text}</p>
      </div>
    </label>
  );
});
