import { AuthContext } from "../context/AuthContext";
import React, { ChangeEvent, KeyboardEventHandler, useContext } from "react";
import {useForm} from 'react-hook-form';
import type { SubmitHandler } from "react-hook-form";
import { Auth } from 'aws-amplify';
import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { IoMdClose} from 'react-icons/io';
import { IoMdArrowBack} from 'react-icons/io';
import { SignUpParams } from "@aws-amplify/auth/lib-esm/types";
import { useState } from "react";
import {useRef} from "react"
import { KeyboardEvent } from "react";
import { useEffect } from "react";
import Popup from "./login/popup";
import PopupHeader from "./login/popupheader";
import Submitbutton from "./login/submitbutton";
import FocusTrap from "focus-trap-react";


export type Props = {
    email: string;
    pageChanger : React.Dispatch<React.SetStateAction<string>>;
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;
    setCode : React.Dispatch<React.SetStateAction<string>>;
    mail : string;
    page : string;
}

const VerifyChangePassword = ({email, pageChanger, setLoginShown, setCode, mail, page}: Props) => {

    const auth = useContext(AuthContext);
    // const {register, handleSubmit, formState:{errors}} = useForm<Props>({
    //   defaultValues: {
    //     email : "", 
    //     code1 : "", 
    //     code2 : "", 
    //     code3 : "", 
    //     code4 : "", 
    //     code5 : "", 
    //     code6 : "", 
    //   },
    // });

    function convertEmail(email: string): string {
      const atIndex = email.lastIndexOf('@');
      if (atIndex === -1) {
        // Invalid email format, return as is
        return email;
      }
      
      const username = email.substring(0, atIndex);
      const asterisks = '*'.repeat(username.length - 3);
      const convertedEmail = `${asterisks}${email.substring(atIndex - 3)}`;
      
      return convertedEmail;
    }
    

    const secondRef= useRef(null);
    const firstRef = useRef<HTMLInputElement>(null);
    const thirdRef = useRef(null);
    const fourthRef = useRef(null);
    const fifthRef = useRef(null);
    const sixthRef = useRef(null);
    const loginButtonRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [resent, setResent] = useState(false);
    const [anerror, setError] = useState(false);
    const [anerror2, setError2] = useState(false);
    const [four, setFour] = useState(true);
    const [five, setFive] = useState(true);
    const [six, setSix] = useState(true);
    const [three, setThree] = useState(true);
    const [second, setSecond] = useState(true);
    const [code1, setCode1] = useState("");
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");
    const [code4, setCode4] = useState("");
    const [code5, setCode5] = useState("");
    const [code6, setCode6] = useState("");


    const maxL = 1;
    const minL = 0;

    const handleActive = () => {
      if (firstRef.current && code1 === "" && code2 === "") {
        firstRef.current.focus();
      }
    }
  
    

    const onOneChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCode1(e.target.value);
        console.log('Current value:', currentValue);
        // Perform additional logic with the current value
        if (currentValue.length === maxL) {
            secondRef.current.focus();
        }
      };

      const onTwoKeyHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && second ) {
            firstRef.current.focus();
        }
        if (e.key === "Enter") {
          loginButtonRef.current.click();
        }
        
      };

      const onTwoChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCode2(e.target.value);
        console.log('Current value:', currentValue);
        // Perform additional logic with the current value
        if (currentValue.length === maxL) {
            setSecond(false);
            thirdRef.current.focus();
        }
        if (currentValue.length === minL) {
            setSecond(true);
        }
      };

      const onThreeKeyHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && three ) {
            secondRef.current.focus();
        }
        if (e.key === "Enter") {
          loginButtonRef.current.click();
        }
        
      };

      const onThreeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCode3(e.target.value);
        console.log('Current value:', currentValue);
        // Perform additional logic with the current value
        if (currentValue.length === maxL) {
            setThree(false);
            fourthRef.current.focus();
        }
        if (currentValue.length === minL) {
            setThree(true);
        }
      };

      const onFourKeyHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && four ) {
            thirdRef.current.focus();
        }
        if (e.key === "Enter") {
          loginButtonRef.current.click();
        }
        
      };

      const onFourChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCode4(e.target.value);
        console.log('Current value:', currentValue);
        // Perform additional logic with the current value
        if (currentValue.length === maxL) {
            setFour(false);
            fifthRef.current.focus();
        }
        if (currentValue.length === minL) {
            setFour(true);
        }
      };

      const onFiveKeyHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && five ) {
            fourthRef.current.focus();
        }
        else if (e.key === "Enter") {
          loginButtonRef.current.click();
        }
        
      };

      const onFiveChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCode5(e.target.value);
        console.log('Current value:', currentValue);
        // Perform additional logic with the current value
        if (currentValue.length === maxL) {
            setFive(false);
            sixthRef.current.focus();
        }
        if (currentValue.length === minL) {
            setFive(true);
        }
      };

      const onSixKeyHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && six ) {
            fifthRef.current.focus();
        }
        else if (e.key === "Enter") {
          loginButtonRef.current.click();
        }
        
      };

      const onSixChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCode6(e.target.value);
        console.log('Current value:', currentValue);
        // Perform additional logic with the current value
        if (currentValue.length === maxL) {
            setSix(false);
            
        }
        if (currentValue.length === minL) {
            setSix(true);
        }
      };

      const handleKeyDown = async (event : React.KeyboardEvent) => {
        if (event.key === "Enter") {
          handleVerifyClick();
        }
      }

      const handleVerifyClick = async () => {
        setLoading(true);
        const code = code1 + code2 + code3 + code4 + code5 + code6;
        console.log(code);
        // Perform the rest of your code here, e.g., call the API, update state, etc.
        try {
          await Auth.forgotPasswordSubmit(mail, code, "121");
        } catch (err) {
          console.log(err);
          console.log("space");
          console.log(err.message);
          if (err.message === "Invalid verification code provided, please try again." ){
            setError(true);
          }
          else if (err.message === "Attempt limit exceeded, please try after some time."){
            setError2(true);
          }
          else {
            setCode(code);
            setError(false);
            setError2(false);
            pageChanger("ChangePassword");
            
          }
        } finally {
          setLoading(false);
        }
      };

    const [resendError, setResendError] = useState(false);

    async function resendConfirmationCode() { 
      setResent(false);
      setResendError(false);  
      Auth.forgotPassword(mail)
        .then((newdata) => setResent(true))
        .catch((err) => {
            console.log('error');
            setResendError(true);
        });
    }
    
    const forgot = () => {
      setResent(false);
      setResendError(false);
      setError(false);
      pageChanger("ForgotPassword");
    }

    const hide = () => setLoginShown(false);

    useEffect(() => {
        const handleKeys = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            hide();
          }
        };
    
        window.addEventListener('keydown', handleKeys);
    
        return () => {
          window.removeEventListener('keydown', handleKeys);
        };
      }, [hide]);



    return (
        <div>
          <Popup height = "55">
            <FocusTrap active = {page === "VerifyChangePassword"} focusTrapOptions={{clickOutsideDeactivates : false, allowOutsideClick : false, onActivate : handleActive}}>
              <div>
            
              <PopupHeader arrow = {true} onArrowClick={forgot} onClick={hide} popupSize = "medium" boldtext = "Verify Your Account"/>
                  <div className= " ml-22 sm:ml-18 mr-22 sm:mr-18 h-[5vh] flex flex-col text-center mt-[1vh]">
                        <span className="text-1xl w-full font-poppins mb-[5vh] z-0 "> Please confirm your account to reset your password by entering the verification code sent to <div className = "font-bold text-1xl mx-auto w-4/5 font-poppins text-center">{convertEmail(mail)}</div> </span>
                    </div>
                      <div className="flex justify-center mt-[9vh] sm:mt-[4vh] z-0">
                      <div className="flex space-x-2">
                        <input ref = {firstRef} onChange = {onOneChangeHandler} id="input1" type="text" style={{borderColor: anerror? "red": "rgb(156 163 175)"}}                             className="h-[9vh] w-[9vw] sm:w-[4vw]  text-2xl sm:text-4xl font-bold sm:font-semibold text-center bg-transparent border-b-2 z-99 border-gray-400 focus:outline-none pb-2 leading-12 font-poppins" maxLength={1}/>
                        <input ref = {secondRef} onKeyDown={onTwoKeyHandler} onChange = {onTwoChangeHandler} id="input2" type="text" style={{borderColor: anerror? "red": "rgb(156 163 175)"}}className="h-[9vh] w-[9vw] sm:w-[4vw]  text-2xl sm:text-4xl font-bold sm:font-semibold text-center bg-transparent border-b-2 z-99 border-gray-400 focus:outline-none pb-2 leading-12 font-poppins" maxLength={1}/>
                        <input ref = {thirdRef} onKeyDown={onThreeKeyHandler} onChange={onThreeChangeHandler} id="input3" type="text" style={{borderColor: anerror? "red": "rgb(156 163 175)"}}className="h-[9vh] w-[9vw] sm:w-[4vw]  text-2xl sm:text-4xl font-bold sm:font-semibold text-center bg-transparent border-b-2 z-99 border-gray-400 focus:outline-none pb-2 leading-12 font-poppins" maxLength={1}/>
                        <input ref = {fourthRef} onKeyDown = {onFourKeyHandler} onChange = {onFourChangeHandler} id="input4" type="text" style={{borderColor: anerror? "red": "rgb(156 163 175)"}}className="h-[9vh] w-[9vw] sm:w-[4vw]  text-2xl sm:text-4xl font-bold sm:font-semibold text-center bg-transparent border-b-2 z-99 border-gray-400 focus:outline-none pb-2 leading-12 font-poppins" maxLength={1}/>
                        <input ref = {fifthRef} onKeyDown = {onFiveKeyHandler} onChange= {onFiveChangeHandler} id="input5" type="text" style={{borderColor: anerror? "red": "rgb(156 163 175)"}}className="h-[9vh] w-[9vw] sm:w-[4vw]  text-2xl sm:text-4xl font-bold sm:font-semibold text-center bg-transparent border-b-2 z-99 border-gray-400 focus:outline-none pb-2 leading-12 font-poppins" maxLength={1}/>
                        <input ref = {sixthRef} onKeyDown = {onSixKeyHandler} onChange = {onSixChangeHandler} id="input6" type="text" style={{borderColor: anerror? "red": "rgb(156 163 175)"}}className="h-[9vh] w-[9vw] sm:w-[4vw]  text-2xl sm:text-4xl font-bold sm:font-semibold text-center bg-transparent border-b-2 z-99 border-gray-400 focus:outline-none pb-2 leading-12 font-poppins" maxLength={1}/>
                        </div>
                      </div>
                      <p role = "alert" style = {{visibility: anerror? "visible" : "hidden"}}className= "font-poppins text-red-500 italic text-xs sm:text-sm ml-9 mr-9 text-center mt-[1vh]">Incorect verification code. Try entering your verification code again.</p>
                      <p role = "alert" style = {{visibility: anerror2? "visible" : "hidden"}}className= "font-poppins text-red-500 italic text-xs sm:text-sm ml-9 mr-9 text-center mt-[1vh]">Attempt limit exceeded. Please try again after some time. </p> 
                      <p className="text-center text-xs sm:text-sm mt-[-3vh] sm:mt-[1vh]">
                          <span className="text-gray-800">Haven't received it yet? </span>
                          <button onClick= {resendConfirmationCode} className="text-blue-500 underline font-semibold">Resend Code</button>
                      </p>
                      
                      <p role = "alert" style = {{visibility: resent? "visible" : "hidden"}}className= "font-poppins text-green-500 italic text-xs sm:text-sm text-center mt-[1vh]">Verification Code Resent Successfully</p>
                      <p role = "alert" style = {{visibility: resendError? "visible" : "hidden", marginTop: resendError ? "-2vh" : "",  marginBottom: resendError ? "3vh" : ""}}className= "font-poppins text-red-500 italic text-xs sm:text-sm text-center">Attempt limit exceeded. Please try again after some time. </p> 
                      <div className = "mt-[-5vh] sm:mt-[-4vh]"></div>
                      <Submitbutton text = "Verify" loading = {loading}
                      onClick = {handleVerifyClick} onKeyDown={handleKeyDown} ref = {loginButtonRef}/>
                      
                    </div>
                </FocusTrap>
            </Popup>

        </div>
    )
}


export default VerifyChangePassword;