import { SearchQuery } from "./search";
import { Link } from "react-router-dom";
import SpotCard from "./spotcard";
import { useSpotSearch } from "../hooks/useSpotSearch";

export interface SearchQueryResultsProps {
  query: SearchQuery;
  limit?: number
}

export const SearchQueryResults = ({ query, limit }: SearchQueryResultsProps) => {
  const { loading, error, spots } = useSpotSearch(query);

  if (loading) return <p>Loading</p>;
  if (error) return <p>{error.message}</p>;

  return (
    <>
      {spots.filter((_, index) => !limit || limit > index).map((spot) => {
        return (
          <Link
            to={`/spot/${spot.id}?s=1${
              query?.dates?.start && `&start=${query.dates.start}`
            }${query?.dates?.end && `&end=${query.dates.end}`}`}
          >
            <div className="border-t-2">
              <SpotCard
                spot={spot}
                key={spot.id}
                start={query?.dates?.start.toDateString() ?? ""}
                end={query?.dates?.end.toDateString() ?? ""}
              />
            </div>
          </Link>
        );
      })}
    </>
  );
};
