const useDateText = (start: Date | string, end: Date | string) => {
  const startDate = getDateText(start);
  const endDate = getDateText(end);

  if (startDate.short === endDate.short) {
    return {
      singular: true,
      ...startDate,
    };
  } else {
    return {
      singular: false,
      short: `${startDate.short} to ${endDate.short}`,
      long: `${startDate.long} to ${endDate.long}`,
    };
  }
};

const getDateText = (d: Date | string) => {
  const date = new Date(d);
  const overElevenMonths = Date.now() + 28927800000 < date.getTime();
  if (overElevenMonths) {
    return {
      short: date.toLocaleDateString("default", {
        month: "short",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC",
      }),
      long: date.toLocaleDateString("default", {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC",
      }),
    };
  } else {
    return {
      short: date.toLocaleDateString("default", {
        month: "short",
        day: "numeric",
        timeZone: "UTC",
      }),
      long: date.toLocaleDateString("default", {
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      }),
    };
  }
};

export { useDateText, getDateText };
