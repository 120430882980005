import { useContext, useEffect } from "react";
import { StripeContext } from "../context/StripeProvider";
import { useMutation } from "@apollo/client";
import { gql } from "../__generated__";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Button from "./button";

export const addPaymentMethod = gql(`
  mutation Mutation {
    addPaymentMethod {
      clientSecret
      stripePublishableKey
    }
  }
`);

interface AddPaymentMethodFormProps {
  redirectUrl: string;
  disabled?: boolean;
}

export const AddPaymentMethodForm = ({
  redirectUrl,
  disabled = false,
}: AddPaymentMethodFormProps) => {
  const stripe = useContext(StripeContext);

  const [getData, { data }] = useMutation(addPaymentMethod);

  useEffect(() => {
    if (getData) getData();
  }, [getData]);

  if (!data?.addPaymentMethod)
    return (
      <div>
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-blue-500 border-t-blue-600 border-solid"></div>
      </div>
    );

  const { stripePublishableKey, clientSecret } = data.addPaymentMethod;

  if (stripePublishableKey) {
    stripe.registerApiKey(stripePublishableKey);
  }

  if (stripe.stripePromise == null || clientSecret == null) return null;

  return (
    <Elements
      stripe={stripe.stripePromise}
      options={{ clientSecret: clientSecret }}
    >
      <EntryForm redirectUrl={redirectUrl} disabled={disabled} />
    </Elements>
  );
};

const EntryForm = (props: AddPaymentMethodFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: props.redirectUrl,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div>
      <PaymentElement />
      <Button
        type="primary"
        onClick={handleSubmit}
        disabled={props.disabled}
        text="Submit Reservation"
        className="mt-10 w-full"
      />
    </div>
  );
};
