import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import {useForm, SubmitHandler} from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { IoMdClose} from 'react-icons/io';
import { SignUpParams } from "@aws-amplify/auth/lib-esm/types";
import { useState } from "react";
import { resetApolloContext } from "@apollo/client";
import {useRef, useEffect} from 'react';
import { AiFillApple } from "react-icons/ai";
import { TokenEndpointResponse } from "../helpers/CognitoConnector";
import {FcGoogle} from 'react-icons/fc'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Popup from "./login/popup";
import PopupHeader from "./login/popupheader";
import Textfield from "./textfield/textfield";
import Submitbutton from "./login/submitbutton";
import FocusTrap from "focus-trap-react";

export type Props = {
    email: string;
    firstName : string;
    lastName : string;
    password: string;
    pageChanger : React.Dispatch<React.SetStateAction<string>>;
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;
    setEmail : React.Dispatch<React.SetStateAction<string>>;
}

const CreateAccount = ({ email, password, firstName, lastName, pageChanger, setLoginShown, setEmail}: Props) => {
    const loginButtonRef = useRef(null);
    const auth = useContext(AuthContext);
    const {register, handleSubmit, reset, formState:{errors}} = useForm<Props>({defaultValues: {
        email : "", 
        password : "", 
        firstName: "", 
        lastName: "",
    }});

    useEffect(() => {
        if (loginButtonRef.current) {
          loginButtonRef.current.focus();
        }
      }, []);

    const handleKeyDown = (event : React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            loginButtonRef.current.click();
        }
      };

    const [loading, setLoading] = useState(false);
    const [anerror, setError] = useState(false);
    const [message, setMessage ] = useState('');
    const [anerror2, setError2] = useState(false);
    const [message2, setMessage2] = useState('');


    const onSubmit: SubmitHandler<Props> = async data => { 
        handleError();
        setLoading(true);
        try {
        const { user } = await Auth.signUp({
           username:  data.email,
           password:  data.password, 
          attributes: {
            given_name: data.firstName,          // optional
            family_name: data.lastName,   // optional - E.164 number convention
            // other custom attributes 
          },
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
          }
        });
        setEmail(data.email);
        
        pageChanger("VerifyCreateAccount");
        reset();
        console.log(user);
      } catch (error) {
        console.log(error);
        if (error.message === "Password did not conform with policy: Password must have lowercase characters"){
            setMessage2("Password must contain lowercase letters.");
            setError2(true);
        }
        if (error.message === "Password did not conform with policy: Password must have uppercase characters"){
            setMessage2("Password must contain uppercase letters.");
            setError2(true);
        }
        if (error.message === "Password did not conform with policy: Password must have numeric characters"){
            setMessage2("Password must contain a number.");
            setError2(true);
        }
        if (error.message === "Password did not conform with policy: Password must have symbol characters"){
            setMessage2("Password must contain a symbol.");
            setError2(true);
        }
        if (error.message === "Password did not conform with policy: Password not long enough") {
            setMessage2("Password is not long enough.");
            setError2(true);
        }
        if (error.message === "An account with the given email already exists.") {
            setMessage("An account already exists.");
            setError(true);
        }
      } finally {
        setLoading(false);
      }
    }

    const handleError = () => {
        setError(false);
        setError2(false);
        setMessage('');
        setMessage2('');
    }

    const hide = () => {
        setLoginShown(false);
        reset();
        handleError();
    }

    useEffect(() => {
        const handleKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            hide();
          }
        };
    
        window.addEventListener('keydown', handleKey);
    
        return () => {
          window.removeEventListener('keydown', handleKey);
        };
      }, [hide]);

    const login = () => {
        reset();
        handleError();
        pageChanger("Login");
    }
    


    return (
        <div>
            <Popup height = "60">
                
             <FocusTrap active = {true} focusTrapOptions={{clickOutsideDeactivates : false, allowOutsideClick : false}}>
             <div>
                <PopupHeader boldtext="Create Account" onClick={hide}></PopupHeader>
                    
                    <div className = "mt-[-4vh] ml-12 mr-12"></div>
                    <form noValidate = {true} onSubmit={handleSubmit(onSubmit)} className="!top-12">
                        <div className="flex justify-between">
                          <div className = "ml-4 sm:ml-12">
                            <Textfield
                            type = "text"
                            onKeyDown={handleKeyDown}
                            {...register("firstName", { required: '*Required' })}
                            id = "firstName"
                            placeholder="First Name"
                            height = "3vh"
                            label = "First Name"
                            stage = "first"
                            width="40"
                            
                            />
                          </div>
                          <div className = "mr-4 sm:mr-12">
                            <Textfield
                            type = "text"
                            onKeyDown={handleKeyDown}
                            {...register("lastName", { required: '*Required' })}
                            id = "lastName"
                            placeholder="Last Name"
                            height = "3vh"
                            label = "Last Name"
                            stage = "last"
                            width="40"
                           
                            />
                          </div>
                        </div>

                        <Textfield label = "Email Address" 
                        placeholder="Email Address" 
                        {...register("email", { required:  true})}
                        type="email" 
                        onKeyDown={handleKeyDown} 
                        error = {anerror}
                        validationMessage= {message} 
                        height = "3vh"                     
                        id="email"/>
                        
                        <Textfield label = "Password" 
                        placeholder="Password" 
                        {...register("password", { required:  true})}
                        type="password" 
                        onKeyDown={handleKeyDown} 
                        error = {anerror2}
                        height = "3vh"
                        validationMessage= {message2}  
                        id="password"/>

                        <p className="text-center">
                        <span className="text-gray-400">By registering, you agree to BuyMySpot's </span>
                        <button onClick={() => window.open("https://www.buymyspot.com/terms-of-use", "_blank")} className="text-blue-500 underline font-semibold">Terms of Service</button>
                        </p>
                        
                        <Submitbutton text = "Create Account" ref = {loginButtonRef} loading = {loading}/>

                    </form>
                    <div className="flex items-center mx-[8%] mt-[2vh]">
                        <div className="flex-grow h-px bg-gray-300"></div>
                        <span className="mx-2 text-gray-300 font-poppins text-sm">OR</span>
                        <div className="flex-grow h-px bg-gray-300"></div>
                    </div>
                    <div className = "mt-[-2vh]"></div>
                        {/* <Submitbutton text = "Continue with Apple" 
                        onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple})}
                        
                    
                        use = "Apple"/>
                    
                        <div className = "mt-[-4vh]"></div>
                        <Submitbutton text = "Continue with Google" 
                        
                        onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
                        use = "Google"/> */}
                    <p className="text-center mt-[4vh]">
                        <span className="text-gray-400">Already have an account? </span>
                        <button onClick= {login} className="text-blue-500 underline font-semibold">Log in</button>
                    </p>
                    </div>
                    </FocusTrap>
                </Popup>
        </div>
    )
}


export default CreateAccount;