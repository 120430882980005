/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation Mutation {\n    addPaymentMethod {\n      clientSecret\n      stripePublishableKey\n    }\n  }\n": types.MutationDocument,
    "mutation CreateLeaseRequest($data: CreateLeaseRequestData) {\n    createLeaseRequest(data: $data) {\n      code\n      message\n      success\n      lease {\n        id\n      }\n    }\n  }": types.CreateLeaseRequestDocument,
    "query GetInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      spotId\n      rentalPeriod {\n        start\n        end\n      }\n      rentalLength {\n        months\n        days\n        totalDays\n      }\n      subscription\n      payments {\n        due\n        subtotal\n        fees\n        total\n      }\n      summary {\n        price\n        quantity\n        unit\n      }\n      token\n    }\n  }": types.GetInvoiceDocument,
    "mutation AddPaymentMethod {\n    addPaymentMethod {\n      clientSecret\n      code\n      customer\n      message\n      stripePublishableKey\n      success\n    }\n  }": types.AddPaymentMethodDocument,
    "query GetSpotDatePickerQuery($spotId: ID!) {\n    spot(id: $spotId) {\n      id\n      period {\n        start\n        end\n      }\n      minimumLeaseRange\n    }\n  }": types.GetSpotDatePickerQueryDocument,
    "query DatepickerVerifyInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n    }\n  }": types.DatepickerVerifyInvoiceDocument,
    "\nmutation AddSpotImage {\n  addSpotImage {\n    code\n    success\n    message\n    imageId\n    uploadUrl\n    destinationUrl\n  }\n}\n": types.AddSpotImageDocument,
    "query GetInvoiceData($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      spotId\n      rentalPeriod {\n        start\n        end\n      }\n      rentalLength {\n        months\n        days\n        totalDays\n      }\n      subscription\n      payments {\n        due\n        subtotal\n        fees\n        total\n      }\n      summary {\n        price\n        quantity\n        unit\n      }\n      token\n    }\n  }": types.GetInvoiceDataDocument,
    "query PurchaseBarVerifyInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n      summary {\n        price\n        unit\n      }\n      payments {\n        total\n      }\n    }\n  }": types.PurchaseBarVerifyInvoiceDocument,
    "\n  mutation CreateThread($userId: ID!, $message: String!) {\n    createThread(userId: $userId, message: $message) {\n      code\n      success\n      message\n      sentMessage {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n": types.CreateThreadDocument,
    "query GetCheckoutToken($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n    }\n  }": types.GetCheckoutTokenDocument,
    "\n  query Properties {\n    properties {\n      address\n      countPendingLeases\n      countSpotTypes\n      id\n      name\n      status\n      position {\n        lat\n        lng\n      }\n    }\n  }\n": types.PropertiesDocument,
    "mutation UpdateProperty($updatePropertyId: ID!, $data: PropertyUpdateData!) {\n      updateProperty(id: $updatePropertyId, data: $data) {\n        success\n        property {\n          id\n          status\n        }\n      }\n    }": types.UpdatePropertyDocument,
    "mutation DeleteProperty($deletePropertyId: ID!) {\n      deleteProperty(id: $deletePropertyId) {\n        success\n        message\n        code\n      }\n    }": types.DeletePropertyDocument,
    "\n    mutation CreateProperty($data: PropertyData!) {\n      createProperty(data: $data) {\n        code\n        property {\n          id\n        }\n        success\n      }\n    }\n  ": types.CreatePropertyDocument,
    "\n  query GetEditingSpot($spotId: ID!) {\n    spot(id: $spotId) {\n      address\n      carType\n      description\n      evCharging\n      minimumLeaseRange\n      nickname\n      numberOfSpaces\n      parkingPass\n      period {\n        end\n        start\n      }\n      pricing {\n        daily\n        weekly\n        monthly\n      }\n      restroom\n      securityCameras\n      sheltered\n      tailgating\n      images {\n        href\n      }\n    }\n  }\n": types.GetEditingSpotDocument,
    "\n  mutation CreateSpot($data: SpotData!) {\n    createSpot(data: $data) {\n      code\n      message\n      success\n    }\n  }\n": types.CreateSpotDocument,
    "\n  mutation UpdateSpot($id: ID!, $data: SpotData!) {\n    updateSpot(id: $id, data: $data) {\n      code\n      message\n      success\n    }\n  }\n": types.UpdateSpotDocument,
    "\nquery Properties {\n  properties {\n    address\n    countPendingLeases\n    countSpotTypes\n    id\n    name\n    status\n    position {\n      lat\n      lng\n    }\n  }\n}\n": types.PropertiesDocument,
    "\n  query GetSpotTypesQuery($propertyId: ID) {\n    mySpots(propertyId: $propertyId) {\n      id\n      name\n      numberOfSpaces\n      status\n      property {\n        position {\n          lat\n          lng\n        }\n      }\n    }\n  }\n": types.GetSpotTypesQueryDocument,
    "mutation ChangeSpotStatus($updateSpotId: ID!, $data: SpotData!) {\n      updateSpot(id: $updateSpotId, data: $data) {\n      spot {\n        id\n        status\n      }\n      success\n    }\n  }": types.ChangeSpotStatusDocument,
    "mutation DeleteSpot($deleteSpotId: ID!) {\n      deleteSpot(id: $deleteSpotId) {\n        success\n      }\n    }": types.DeleteSpotDocument,
    "\n  query GET_THREADS {\n    threads {\n      threads {\n        id\n        title\n        members {\n          id\n          name\n          profilePicture\n        }\n        lastMessage {\n          id\n          timestamp\n          text\n          type\n          authorId\n          threadId\n        }\n      }\n    }\n  }\n": types.Get_ThreadsDocument,
    "query GetSpotPageDataQuery($spotId: ID!) {\n  spot(id: $spotId) {\n    id\n    name\n    position {\n      lat\n      lng\n    }\n  }\n}": types.GetSpotPageDataQueryDocument,
    "\n  query GetMessagesInThread($threadId: ID!) {\n    messages(threadId: $threadId) {\n      messages {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n": types.GetMessagesInThreadDocument,
    "\n  mutation SendMessage($threadId: ID!, $message: String!) {\n    sendMessage(threadId: $threadId, message: $message) {\n      code\n      success\n      message\n      sentMessage {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n": types.SendMessageDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Mutation {\n    addPaymentMethod {\n      clientSecret\n      stripePublishableKey\n    }\n  }\n"): (typeof documents)["\n  mutation Mutation {\n    addPaymentMethod {\n      clientSecret\n      stripePublishableKey\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation CreateLeaseRequest($data: CreateLeaseRequestData) {\n    createLeaseRequest(data: $data) {\n      code\n      message\n      success\n      lease {\n        id\n      }\n    }\n  }"): (typeof documents)["mutation CreateLeaseRequest($data: CreateLeaseRequestData) {\n    createLeaseRequest(data: $data) {\n      code\n      message\n      success\n      lease {\n        id\n      }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      spotId\n      rentalPeriod {\n        start\n        end\n      }\n      rentalLength {\n        months\n        days\n        totalDays\n      }\n      subscription\n      payments {\n        due\n        subtotal\n        fees\n        total\n      }\n      summary {\n        price\n        quantity\n        unit\n      }\n      token\n    }\n  }"): (typeof documents)["query GetInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      spotId\n      rentalPeriod {\n        start\n        end\n      }\n      rentalLength {\n        months\n        days\n        totalDays\n      }\n      subscription\n      payments {\n        due\n        subtotal\n        fees\n        total\n      }\n      summary {\n        price\n        quantity\n        unit\n      }\n      token\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation AddPaymentMethod {\n    addPaymentMethod {\n      clientSecret\n      code\n      customer\n      message\n      stripePublishableKey\n      success\n    }\n  }"): (typeof documents)["mutation AddPaymentMethod {\n    addPaymentMethod {\n      clientSecret\n      code\n      customer\n      message\n      stripePublishableKey\n      success\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetSpotDatePickerQuery($spotId: ID!) {\n    spot(id: $spotId) {\n      id\n      period {\n        start\n        end\n      }\n      minimumLeaseRange\n    }\n  }"): (typeof documents)["query GetSpotDatePickerQuery($spotId: ID!) {\n    spot(id: $spotId) {\n      id\n      period {\n        start\n        end\n      }\n      minimumLeaseRange\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query DatepickerVerifyInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n    }\n  }"): (typeof documents)["query DatepickerVerifyInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddSpotImage {\n  addSpotImage {\n    code\n    success\n    message\n    imageId\n    uploadUrl\n    destinationUrl\n  }\n}\n"): (typeof documents)["\nmutation AddSpotImage {\n  addSpotImage {\n    code\n    success\n    message\n    imageId\n    uploadUrl\n    destinationUrl\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetInvoiceData($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      spotId\n      rentalPeriod {\n        start\n        end\n      }\n      rentalLength {\n        months\n        days\n        totalDays\n      }\n      subscription\n      payments {\n        due\n        subtotal\n        fees\n        total\n      }\n      summary {\n        price\n        quantity\n        unit\n      }\n      token\n    }\n  }"): (typeof documents)["query GetInvoiceData($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      spotId\n      rentalPeriod {\n        start\n        end\n      }\n      rentalLength {\n        months\n        days\n        totalDays\n      }\n      subscription\n      payments {\n        due\n        subtotal\n        fees\n        total\n      }\n      summary {\n        price\n        quantity\n        unit\n      }\n      token\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query PurchaseBarVerifyInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n      summary {\n        price\n        unit\n      }\n      payments {\n        total\n      }\n    }\n  }"): (typeof documents)["query PurchaseBarVerifyInvoice($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n      summary {\n        price\n        unit\n      }\n      payments {\n        total\n      }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateThread($userId: ID!, $message: String!) {\n    createThread(userId: $userId, message: $message) {\n      code\n      success\n      message\n      sentMessage {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateThread($userId: ID!, $message: String!) {\n    createThread(userId: $userId, message: $message) {\n      code\n      success\n      message\n      sentMessage {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetCheckoutToken($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n    }\n  }"): (typeof documents)["query GetCheckoutToken($spotId: String!, $start: String!, $end: String!) {\n    getInvoice(spotId: $spotId, start: $start, end: $end) {\n      token\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Properties {\n    properties {\n      address\n      countPendingLeases\n      countSpotTypes\n      id\n      name\n      status\n      position {\n        lat\n        lng\n      }\n    }\n  }\n"): (typeof documents)["\n  query Properties {\n    properties {\n      address\n      countPendingLeases\n      countSpotTypes\n      id\n      name\n      status\n      position {\n        lat\n        lng\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation UpdateProperty($updatePropertyId: ID!, $data: PropertyUpdateData!) {\n      updateProperty(id: $updatePropertyId, data: $data) {\n        success\n        property {\n          id\n          status\n        }\n      }\n    }"): (typeof documents)["mutation UpdateProperty($updatePropertyId: ID!, $data: PropertyUpdateData!) {\n      updateProperty(id: $updatePropertyId, data: $data) {\n        success\n        property {\n          id\n          status\n        }\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation DeleteProperty($deletePropertyId: ID!) {\n      deleteProperty(id: $deletePropertyId) {\n        success\n        message\n        code\n      }\n    }"): (typeof documents)["mutation DeleteProperty($deletePropertyId: ID!) {\n      deleteProperty(id: $deletePropertyId) {\n        success\n        message\n        code\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateProperty($data: PropertyData!) {\n      createProperty(data: $data) {\n        code\n        property {\n          id\n        }\n        success\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateProperty($data: PropertyData!) {\n      createProperty(data: $data) {\n        code\n        property {\n          id\n        }\n        success\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetEditingSpot($spotId: ID!) {\n    spot(id: $spotId) {\n      address\n      carType\n      description\n      evCharging\n      minimumLeaseRange\n      nickname\n      numberOfSpaces\n      parkingPass\n      period {\n        end\n        start\n      }\n      pricing {\n        daily\n        weekly\n        monthly\n      }\n      restroom\n      securityCameras\n      sheltered\n      tailgating\n      images {\n        href\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetEditingSpot($spotId: ID!) {\n    spot(id: $spotId) {\n      address\n      carType\n      description\n      evCharging\n      minimumLeaseRange\n      nickname\n      numberOfSpaces\n      parkingPass\n      period {\n        end\n        start\n      }\n      pricing {\n        daily\n        weekly\n        monthly\n      }\n      restroom\n      securityCameras\n      sheltered\n      tailgating\n      images {\n        href\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSpot($data: SpotData!) {\n    createSpot(data: $data) {\n      code\n      message\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSpot($data: SpotData!) {\n    createSpot(data: $data) {\n      code\n      message\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSpot($id: ID!, $data: SpotData!) {\n    updateSpot(id: $id, data: $data) {\n      code\n      message\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSpot($id: ID!, $data: SpotData!) {\n    updateSpot(id: $id, data: $data) {\n      code\n      message\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Properties {\n  properties {\n    address\n    countPendingLeases\n    countSpotTypes\n    id\n    name\n    status\n    position {\n      lat\n      lng\n    }\n  }\n}\n"): (typeof documents)["\nquery Properties {\n  properties {\n    address\n    countPendingLeases\n    countSpotTypes\n    id\n    name\n    status\n    position {\n      lat\n      lng\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSpotTypesQuery($propertyId: ID) {\n    mySpots(propertyId: $propertyId) {\n      id\n      name\n      numberOfSpaces\n      status\n      property {\n        position {\n          lat\n          lng\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSpotTypesQuery($propertyId: ID) {\n    mySpots(propertyId: $propertyId) {\n      id\n      name\n      numberOfSpaces\n      status\n      property {\n        position {\n          lat\n          lng\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation ChangeSpotStatus($updateSpotId: ID!, $data: SpotData!) {\n      updateSpot(id: $updateSpotId, data: $data) {\n      spot {\n        id\n        status\n      }\n      success\n    }\n  }"): (typeof documents)["mutation ChangeSpotStatus($updateSpotId: ID!, $data: SpotData!) {\n      updateSpot(id: $updateSpotId, data: $data) {\n      spot {\n        id\n        status\n      }\n      success\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation DeleteSpot($deleteSpotId: ID!) {\n      deleteSpot(id: $deleteSpotId) {\n        success\n      }\n    }"): (typeof documents)["mutation DeleteSpot($deleteSpotId: ID!) {\n      deleteSpot(id: $deleteSpotId) {\n        success\n      }\n    }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GET_THREADS {\n    threads {\n      threads {\n        id\n        title\n        members {\n          id\n          name\n          profilePicture\n        }\n        lastMessage {\n          id\n          timestamp\n          text\n          type\n          authorId\n          threadId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GET_THREADS {\n    threads {\n      threads {\n        id\n        title\n        members {\n          id\n          name\n          profilePicture\n        }\n        lastMessage {\n          id\n          timestamp\n          text\n          type\n          authorId\n          threadId\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetSpotPageDataQuery($spotId: ID!) {\n  spot(id: $spotId) {\n    id\n    name\n    position {\n      lat\n      lng\n    }\n  }\n}"): (typeof documents)["query GetSpotPageDataQuery($spotId: ID!) {\n  spot(id: $spotId) {\n    id\n    name\n    position {\n      lat\n      lng\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetMessagesInThread($threadId: ID!) {\n    messages(threadId: $threadId) {\n      messages {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMessagesInThread($threadId: ID!) {\n    messages(threadId: $threadId) {\n      messages {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SendMessage($threadId: ID!, $message: String!) {\n    sendMessage(threadId: $threadId, message: $message) {\n      code\n      success\n      message\n      sentMessage {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendMessage($threadId: ID!, $message: String!) {\n    sendMessage(threadId: $threadId, message: $message) {\n      code\n      success\n      message\n      sentMessage {\n        id\n        timestamp\n        text\n        type\n        authorId\n        threadId\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;