export interface Position {
    lng: number;
    lat: number;
  }
  
  export interface Range {
    start: Date | string;
    end: Date | string;
  }
  
  export const calculateDistance = (
    spotPos: Position,
    targetPos: Position
  ): number => {
    // from https://www.movable-type.co.uk/scripts/latlong.html
    const R = 6371e3; // metres
    const φ1 = (spotPos.lat * Math.PI) / 180; // φ, λ in radians
    const φ2 = (targetPos.lat * Math.PI) / 180;
    const Δφ = ((targetPos.lat - spotPos.lat) * Math.PI) / 180;
    const Δλ = ((targetPos.lng - spotPos.lng) * Math.PI) / 180;
  
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const d = R * c; // in metres
    return d;
  }
  
  export const filterByPosition = (
    spotPos: Position,
    targetPos: Position,
    distanceMeters: number
  ): boolean => {
    return calculateDistance(spotPos, targetPos) < distanceMeters;
  };
  
  export const calculatePrettyDistance = (spotPos: Position, targetPos: Position) => {
    let d = calculateDistance(spotPos, targetPos);
    let mi = d / 1609;
    return Number(mi.toFixed(1));
  }
  
  export const filterByPrice = (
    pricing: { daily?: number, weekly?: number, monthly?: number },
    maxPrice: number,
    unit: "day" | "week" | "month"
  ): boolean => {
    const getPriceAtScheme = (pricing: { daily?: number, weekly?: number, monthly?: number }, scheme: string) => {
      switch (scheme) {
        case "month":
          return pricing.monthly;
        case "week":
          return pricing.weekly;
        case "day":
          return pricing.daily;
      }
    };
  
    let price = getPriceAtScheme(pricing, unit);
    if (price == null) return false;
    return price <= maxPrice;
  };
  
  const dateDiff = (first: Date | string, second: Date | string) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.abs(Math.round((new Date(second).getTime() - new Date(first).getTime())/(1000*60*60*24)));
  }

  export const calcMinLeaseRange = (pricing: { daily?: number, weekly?: number, monthly?: number }, minLease = 0) => {
    return Math.max(minLease, Math.min(pricing.daily ? 1 : Infinity, pricing.weekly ? 7 : Infinity, pricing.monthly ? 30 : Infinity))
  }
  
  export const filterByDates = (period: Range, reservations: Range[], dates: Range, dateTolerance = 0, fullRange = false, pricing: { daily?: number, weekly?: number, monthly?: number }, minLease = 0): boolean => {
    if(fullRange){
      return dateDiff(dates.start, period.start) <= dateTolerance && dateDiff(dates.end, period.end) <= dateTolerance
    }

    const length = dateDiff(dates.start, dates.end)
    const min = calcMinLeaseRange(pricing, minLease);
    if(length < min) return false;
  
    const dateIn = (date: Date | string, range: Range) => {
      return new Date(date) >= new Date(range.start) && new Date(date) <= new Date(range.end);
    };
  
    if (!dateIn(dates.start, period) && dateDiff(dates.start, period.start) > dateTolerance) return false;
    if (!dateIn(dates.end, period) && dateDiff(dates.end, period.end) > dateTolerance) return false;
    for (const reservation of reservations) {
      let res: Range = {
        start: new Date(reservation.start),
        end: new Date(reservation.end),
      };
      if (dateIn(res.start, dates)) return false;
      if (dateIn(res.end, dates)) return false;
      if (dateIn(dates.start, res)) return false;
      if (dateIn(dates.end, res)) return false;
    }
  
    return true;
  };
  