const RV = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M134.977 99.3478C134.977 100.363 105.892 101.168 69.9825 101.168C34.1075 101.168 5.02246 100.363 5.02246 99.3478C5.02246 98.3328 34.1075 97.5278 69.9825 97.5278C105.892 97.5278 134.977 98.3328 134.977 99.3478Z"
        fill="#67A9F1"
      />
      <path
        d="M9.7124 79.3979H121.432V89.6529L119.367 93.4329L117.372 90.4579H104.982L101.867 93.5729L100.222 91.193H29.6274L26.8624 88.5329H14.5074L11.8124 91.193L9.7124 79.3979Z"
        fill="#0D1F27"
      />
      <path
        d="M51.8175 52.6929V54.6529H38.2025L36.8025 53.2179L51.8175 52.6929Z"
        fill="#0D1F27"
      />
      <path
        d="M118.072 91.7177C118.072 95.8127 114.712 99.1377 110.617 99.1377C106.487 99.1377 103.162 95.8127 103.162 91.7177C103.162 87.5877 106.487 84.2627 110.617 84.2627C114.712 84.2627 118.072 87.5877 118.072 91.7177Z"
        fill="#2E363D"
      />
      <path
        d="M115.377 91.7179C115.377 94.3429 113.242 96.4778 110.617 96.4778C107.992 96.4778 105.857 94.3429 105.857 91.7179C105.857 89.0579 107.992 86.9229 110.617 86.9229C113.242 86.9229 115.377 89.0579 115.377 91.7179Z"
        fill="#93A5A5"
      />
      <path
        d="M115.097 93.2927L113.067 92.7326L114.922 93.7477C114.876 93.8643 114.817 93.9693 114.747 94.0627L111.877 92.4527C111.737 92.6627 111.562 92.826 111.352 92.9427L112.962 95.8477C112.869 95.8943 112.764 95.941 112.647 95.9877L111.632 94.1677L112.192 96.1977C112.099 96.221 111.994 96.256 111.877 96.3027L110.967 93.1177C110.851 93.141 110.734 93.1527 110.617 93.1527C110.477 93.1527 110.349 93.141 110.232 93.1177L109.357 96.3027C109.241 96.256 109.136 96.221 109.042 96.1977L109.602 94.1677L108.552 95.9877C108.459 95.941 108.366 95.8943 108.272 95.8477L109.882 92.9427C109.672 92.826 109.497 92.6627 109.357 92.4527L106.487 94.0627C106.417 93.9693 106.359 93.8643 106.312 93.7477L108.167 92.7326L106.137 93.2927C106.091 93.176 106.056 93.071 106.032 92.9777L109.217 92.0677C109.171 91.951 109.147 91.8343 109.147 91.7177C109.147 91.5777 109.171 91.4493 109.217 91.3326L106.032 90.4576C106.056 90.341 106.091 90.2243 106.137 90.1076L108.167 90.7027L106.312 89.6527C106.359 89.5593 106.417 89.4543 106.487 89.3377L109.357 90.9826C109.497 90.7493 109.672 90.5743 109.882 90.4576L108.272 87.5527C108.366 87.506 108.459 87.4593 108.552 87.4127L109.602 89.2326L109.042 87.2027C109.136 87.1793 109.241 87.1443 109.357 87.0977L110.232 90.2826C110.349 90.2593 110.477 90.2477 110.617 90.2477C110.734 90.2477 110.851 90.2593 110.967 90.2826L111.877 87.0977C111.994 87.1443 112.099 87.1793 112.192 87.2027L111.632 89.2326L112.647 87.4127C112.764 87.4593 112.869 87.506 112.962 87.5527L111.352 90.4576C111.562 90.5743 111.737 90.7493 111.877 90.9826L114.747 89.3377C114.817 89.4543 114.876 89.5593 114.922 89.6527L113.067 90.7027L115.097 90.1076C115.144 90.2243 115.179 90.341 115.202 90.4576L112.017 91.3326C112.064 91.4493 112.087 91.5777 112.087 91.7177C112.087 91.8343 112.064 91.951 112.017 92.0677L115.202 92.9777C115.179 93.071 115.144 93.176 115.097 93.2927Z"
        fill="#E2E5E7"
      />
      <path
        d="M131.617 82.4429V88.1129C131.617 90.2479 128.887 90.8429 128.887 90.8429L119.367 93.4329C119.484 92.8729 119.542 92.3012 119.542 91.7179C119.542 86.7829 115.552 82.7579 110.617 82.7579C105.682 82.7579 101.692 86.7829 101.692 91.7179C101.692 92.3479 101.751 92.9662 101.867 93.5729H55.3525C51.7125 93.5729 51.6075 91.1929 51.6075 91.1929L51.1175 53.3929H31.3075C21.2975 53.3929 23.3275 45.9029 23.3275 45.9029C25.7775 38.6579 41.4225 38.8329 41.4225 38.8329H125.317C130.217 39.4629 129.902 43.4179 129.902 43.4179V80.6579C131.722 80.8679 131.617 82.4429 131.617 82.4429Z"
        fill="#D1D6D7"
      />
      <path
        d="M55.3526 91.7178C53.7776 91.7178 53.4626 91.1578 53.1126 91.0878L52.6226 51.5728H31.3076C29.2076 51.5728 27.1426 50.8378 25.8476 49.1228C25.4276 48.5278 25.0076 47.8628 25.0076 47.0928C25.0076 46.5678 25.2176 46.0778 25.4626 45.6578C25.9526 44.8878 26.6176 44.2928 27.3176 43.8028C29.1026 42.6128 31.2376 41.9828 33.3026 41.5278C35.4376 41.0378 37.6426 40.7928 39.8126 40.6878C40.3259 40.6645 40.8276 40.6528 41.3176 40.6528H125.178C128.153 41.1078 128.083 43.0678 128.083 43.2778V43.3478V82.3028C128.083 82.3028 129.728 82.4778 129.763 82.4778V82.5128C129.763 82.5128 129.763 82.4778 129.798 82.5128V88.1128C129.798 88.6378 128.678 89.0228 128.468 89.0578L121.363 90.9828C120.978 85.3828 116.323 80.9378 110.618 80.9378C104.668 80.9378 99.8376 85.7678 99.8376 91.7178H55.3526Z"
        fill="url(#paint0_linear_51_5227)"
      />
      <path
        d="M99.7674 65.9929H86.9574C86.7124 65.9929 86.5024 65.7829 86.5024 65.5379C86.5024 65.2579 86.7124 65.0479 86.9574 65.0479H99.7674C100.012 65.0479 100.222 65.2579 100.222 65.5379C100.222 65.7829 100.012 65.9929 99.7674 65.9929Z"
        fill="url(#paint1_linear_51_5227)"
      />
      <path
        d="M99.7674 67.7429H86.9574C86.7124 67.7429 86.5024 67.5329 86.5024 67.2879C86.5024 67.0079 86.7124 66.7979 86.9574 66.7979H99.7674C100.012 66.7979 100.222 67.0079 100.222 67.2879C100.222 67.5329 100.012 67.7429 99.7674 67.7429Z"
        fill="url(#paint2_linear_51_5227)"
      />
      <path
        d="M99.7674 69.4929H86.9574C86.7124 69.4929 86.5024 69.2829 86.5024 69.0379C86.5024 68.7579 86.7124 68.5479 86.9574 68.5479H99.7674C100.012 68.5479 100.222 68.7579 100.222 69.0379C100.222 69.2829 100.012 69.4929 99.7674 69.4929Z"
        fill="url(#paint3_linear_51_5227)"
      />
      <path
        d="M99.7674 71.2429H86.9574C86.7124 71.2429 86.5024 71.0329 86.5024 70.7879C86.5024 70.5079 86.7124 70.2979 86.9574 70.2979H99.7674C100.012 70.2979 100.222 70.5079 100.222 70.7879C100.222 71.0329 100.012 71.2429 99.7674 71.2429Z"
        fill="url(#paint4_linear_51_5227)"
      />
      <path
        d="M66.1674 89.4078C65.7474 89.4078 65.3975 89.0578 65.3975 88.6728V54.7228C65.3975 54.3378 65.7474 53.9878 66.1674 53.9878H81.8825C82.3025 53.9878 82.6525 54.3378 82.6525 54.7228V88.6728C82.6525 89.0578 82.3025 89.4078 81.8825 89.4078H66.1674Z"
        fill="url(#paint5_linear_51_5227)"
      />
      <path
        d="M28.1925 91.7177C28.1925 95.8127 24.8325 99.1377 20.7375 99.1377C16.6075 99.1377 13.2825 95.8127 13.2825 91.7177C13.2825 87.5877 16.6075 84.2627 20.7375 84.2627C24.8325 84.2627 28.1925 87.5877 28.1925 91.7177Z"
        fill="#2E363D"
      />
      <path
        d="M25.4975 91.7179C25.4975 94.3429 23.3625 96.4778 20.7375 96.4778C18.1125 96.4778 15.9775 94.3429 15.9775 91.7179C15.9775 89.0579 18.1125 86.9229 20.7375 86.9229C23.3625 86.9229 25.4975 89.0579 25.4975 91.7179Z"
        fill="#93A5A5"
      />
      <path
        d="M25.2176 93.2927L23.1876 92.7326L25.0426 93.7477C24.9959 93.8643 24.9376 93.9693 24.8676 94.0627L21.9976 92.4527C21.8576 92.6627 21.6826 92.826 21.4726 92.9427L23.0826 95.8477C22.9893 95.8943 22.8842 95.941 22.7676 95.9877L21.7526 94.1677L22.3126 96.1977C22.2192 96.221 22.1143 96.256 21.9976 96.3027L21.0876 93.1177C20.9709 93.141 20.8542 93.1527 20.7376 93.1527C20.5976 93.1527 20.4693 93.141 20.3526 93.1177L19.4776 96.3027C19.3609 96.256 19.2559 96.221 19.1626 96.1977L19.7226 94.1677L18.6726 95.9877C18.5793 95.941 18.4859 95.8943 18.3926 95.8477L20.0026 92.9427C19.7926 92.826 19.6176 92.6627 19.4776 92.4527L16.6076 94.0627C16.5376 93.9693 16.4793 93.8643 16.4326 93.7477L18.2876 92.7326L16.2576 93.2927C16.2109 93.176 16.1759 93.071 16.1526 92.9777L19.3376 92.0677C19.2909 91.951 19.2676 91.8343 19.2676 91.7177C19.2676 91.5777 19.2909 91.4493 19.3376 91.3326L16.1526 90.4576C16.1759 90.341 16.2109 90.2243 16.2576 90.1076L18.2876 90.7027L16.4326 89.6527C16.4793 89.5593 16.5376 89.4543 16.6076 89.3377L19.4776 90.9826C19.6176 90.7493 19.7926 90.5743 20.0026 90.4576L18.3926 87.5527C18.4859 87.506 18.5793 87.4593 18.6726 87.4127L19.7226 89.2326L19.1626 87.2027C19.2559 87.1793 19.3609 87.1443 19.4776 87.0977L20.3526 90.2826C20.4693 90.2593 20.5976 90.2477 20.7376 90.2477C20.8542 90.2477 20.9709 90.2593 21.0876 90.2826L21.9976 87.0977C22.1143 87.1443 22.2192 87.1793 22.3126 87.2027L21.7526 89.2326L22.7676 87.4127C22.8842 87.4593 22.9893 87.506 23.0826 87.5527L21.4726 90.4576C21.6826 90.5743 21.8576 90.7493 21.9976 90.9826L24.8676 89.3377C24.9376 89.4543 24.9959 89.5593 25.0426 89.6527L23.1876 90.7027L25.2176 90.1076C25.2642 90.2243 25.2993 90.341 25.3226 90.4576L22.1376 91.3326C22.1842 91.4493 22.2076 91.5777 22.2076 91.7177C22.2076 91.8343 22.1842 91.951 22.1376 92.0677L25.3226 92.9777C25.2993 93.071 25.2642 93.176 25.2176 93.2927Z"
        fill="#E2E5E7"
      />
      <path
        d="M51.6075 54.1979V91.1929H29.6275C29.3825 86.5029 25.4975 82.7579 20.7375 82.7579C15.9775 82.7579 12.0925 86.5029 11.8125 91.1929H10.6225C6.87746 91.1929 6.38745 86.5029 6.38745 86.5029V80.8329C6.38745 77.0879 8.34746 76.4929 8.34746 76.4929C8.13746 72.3979 10.8674 71.3129 10.8674 71.3129L20.6325 67.2179L32.0775 57.2079C35.6475 53.8829 38.9375 54.1979 38.9375 54.1979H51.6075Z"
        fill="#9FACAD"
      />
      <path
        d="M51.6075 83.7378V91.1928H29.6275C29.4525 87.9378 27.5275 85.1378 24.7625 83.7378H51.6075Z"
        fill="#7B8A8B"
      />
      <path
        d="M6.38745 86.5028V83.7378H16.7125C13.9475 85.1378 12.0225 87.9378 11.8125 91.1928H10.6225C6.87746 91.1928 6.38745 86.5028 6.38745 86.5028Z"
        fill="#7B8A8B"
      />
      <path
        d="M30.9925 85.9081C30.9925 85.9081 29.8025 79.5731 20.0725 79.6781C20.0725 79.6781 29.9775 77.5431 32.9175 85.8031L30.9925 85.9081Z"
        fill="url(#paint6_linear_51_5227)"
      />
      <path
        d="M121.117 85.8378C121.117 85.8378 119.928 79.5028 110.198 79.6078C110.198 79.6078 120.102 77.4728 123.042 85.7328L121.117 85.8378Z"
        fill="url(#paint7_linear_51_5227)"
      />
      <path
        d="M13.4574 76.458H8.34742C8.24242 74.183 9.04741 72.818 9.78241 72.083H13.4574C14.4374 72.083 15.2074 72.888 15.2074 73.833V74.708C15.2074 75.653 14.4374 76.458 13.4574 76.458Z"
        fill="#FFDE17"
      />
      <path
        d="M23.1523 67.3577L33.2323 58.5378C35.8223 56.1228 38.0623 55.9478 38.6923 55.9478C38.7623 55.9478 38.9023 55.9478 38.9023 55.9478H49.8573V67.3577H23.1523Z"
        fill="#0D1F27"
      />
      <path
        d="M47.8272 67.3578H42.1922L33.3022 58.4679C34.5972 57.2779 35.8222 56.6479 36.8022 56.2979L47.8272 67.3578Z"
        fill="#223139"
      />
      <path
        d="M21.1925 67.8481H50.5925V83.0031H33.1975C33.1975 83.0031 30.6775 77.2281 21.1575 78.6281L21.1925 67.8481Z"
        fill="url(#paint8_linear_51_5227)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.6623 89.0579H34.6673V88.9879C34.4223 79.8529 27.6673 78.4879 23.6773 78.4879C22.1723 78.4879 21.1224 78.6979 21.1224 78.6979H21.0173V67.9179H21.0523L32.6023 57.8029C35.4023 55.1779 37.9923 55.0029 38.6923 55.0029C38.7857 55.0029 38.844 55.0029 38.8673 55.0029H38.9373H50.6623V89.0579ZM34.8073 88.8829H50.4873V55.1779L38.8673 55.1429C38.844 55.1429 38.7857 55.1429 38.6923 55.1429C37.9923 55.1429 35.4723 55.3529 32.7073 57.9429L21.1573 68.0229V78.5229C21.4723 78.4529 22.4173 78.3129 23.6773 78.3129C25.6023 78.3129 28.3323 78.6279 30.6423 80.1679C33.2673 81.9179 34.7023 84.8579 34.8073 88.8829Z"
        fill="#4C6767"
      />
      <path
        d="M26.3375 70.1577C25.7775 70.1577 25.3225 69.7027 25.3225 69.1427V64.0677C25.3225 63.5077 25.7775 63.0527 26.3375 63.0527C26.8975 63.0527 27.3525 63.5077 27.3525 64.0677V69.1427C27.3525 69.7027 26.8975 70.1577 26.3375 70.1577Z"
        fill="url(#paint9_linear_51_5227)"
      />
      <path
        d="M48.8075 70.8231H44.5375C44.0825 70.8231 43.6975 70.4381 43.6975 69.9831C43.6975 69.5281 44.0825 69.1431 44.5375 69.1431H48.8075C49.2625 69.1431 49.6475 69.5281 49.6475 69.9831C49.6475 70.4381 49.2625 70.8231 48.8075 70.8231Z"
        fill="#9FACAD"
      />
      <path
        d="M49.6475 70.0181C49.6125 70.4731 49.2625 70.8231 48.8075 70.8231H44.5375C44.0825 70.8231 43.7325 70.4731 43.6975 70.0181H49.6475Z"
        fill="#4C6767"
      />
      <path
        d="M22.1375 72.8877H49.8225L48.9825 73.7277H23.1525L22.1375 72.8877Z"
        fill="#7B8A8B"
      />
      <path
        d="M15.2425 80.798H7.2275C6.9825 78.033 9.18749 77.228 9.18749 77.228H15.4525L15.2425 80.798Z"
        fill="url(#paint10_linear_51_5227)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M110.757 77.823C109.462 77.823 108.377 76.738 108.377 75.443C108.377 74.113 109.462 73.063 110.757 73.063C112.052 73.063 113.137 74.113 113.137 75.443C113.137 76.738 112.052 77.823 110.757 77.823ZM110.757 73.238C109.532 73.238 108.552 74.218 108.552 75.443C108.552 76.633 109.532 77.648 110.757 77.648C111.982 77.648 112.962 76.633 112.962 75.443C112.962 74.218 111.982 73.238 110.757 73.238Z"
        fill="#CBCED1"
      />
      <path
        d="M43.1025 46.1831V44.6431C43.1025 43.6631 43.9075 42.8931 44.8525 42.8931H123.953C124.933 42.8931 125.703 43.6631 125.703 44.6431V46.1831H43.1025Z"
        fill="#8CC63F"
      />
      <path
        d="M33.0576 41.5978C28.1226 42.8228 25.9876 45.8328 25.9876 45.8328L24.1326 44.3978C25.7076 42.1228 28.7526 40.7578 31.9026 39.9878L33.0576 41.5978Z"
        fill="#0D1F27"
      />
      <path
        d="M31.0275 51.6078H51.0825V53.3928H31.3075C21.2975 53.3928 23.3275 45.9028 23.3275 45.9028C23.6075 47.0578 24.9725 51.6428 31.0275 51.6078Z"
        fill="#AEB5B6"
      />
      <path
        d="M53.0775 78.3479H94.8325L111.352 66.6929H126.402L122.307 70.0179H111.807L96.0575 81.6729H53.0425L53.0775 78.3479Z"
        fill="#8CC63F"
      />
      <path
        d="M68.2323 69.5627V73.8326C68.2323 74.2876 67.8473 74.6727 67.3923 74.6727C66.9023 74.6727 66.5173 74.2876 66.5173 73.8326V69.5627C66.5173 69.1077 66.9023 68.7227 67.3923 68.7227C67.8473 68.7227 68.2323 69.1077 68.2323 69.5627Z"
        fill="#D1D6D7"
      />
      <path
        d="M67.4272 68.7227C67.8472 68.7577 68.2322 69.1077 68.2322 69.5627V73.8326C68.2322 74.2876 67.8472 74.6377 67.4272 74.6727V68.7227Z"
        fill="#939E9F"
      />
      <path
        d="M116.707 62.7027H96.9324C95.9874 62.7027 95.1824 61.8977 95.1824 60.9527V54.6877C95.1824 53.7427 95.9874 52.9727 96.9324 52.9727H116.707C117.652 52.9727 118.457 53.7427 118.457 54.6877V60.9527C118.457 61.8977 117.652 62.7027 116.707 62.7027Z"
        fill="#AEB5B6"
      />
      <path
        d="M96.9324 61.8275C96.4424 61.8275 96.0574 61.4425 96.0574 60.9525V54.6875C96.0574 54.2325 96.4424 53.8125 96.9324 53.8125H116.707C117.162 53.8125 117.582 54.2325 117.582 54.6875V60.9525C117.582 61.4425 117.162 61.8275 116.707 61.8275H96.9324Z"
        fill="#0D1F27"
      />
      <path
        d="M100.187 53.8125H106.522L114.502 61.8275H108.202L100.187 53.8125Z"
        fill="#223139"
      />
      <path
        d="M53.1824 52.1331V84.8581H52.3774V51.5381L53.1824 52.1331Z"
        fill="#AEB5B6"
      />
      <path
        d="M119.367 93.433C119.484 92.873 119.542 92.3013 119.542 91.718C119.542 88.218 117.547 85.208 114.607 83.738H121.712L129.657 82.443C129.657 82.443 130.742 82.233 131.547 81.918C131.617 82.198 131.617 82.443 131.617 82.443V88.113C131.617 90.248 128.887 90.843 128.887 90.843L119.367 93.433Z"
        fill="#638D35"
      />
      <path
        d="M51.6074 91.1928L51.5024 83.7378H106.592C103.687 85.2078 101.692 88.2178 101.692 91.7178C101.692 92.3478 101.751 92.9661 101.867 93.5728H55.3524C51.7124 93.5728 51.6074 91.1928 51.6074 91.1928Z"
        fill="#638D35"
      />
      <path
        d="M51.5724 91.7877H32.1124C31.7274 91.7877 31.3774 91.4727 31.3774 91.0527C31.3774 90.6327 31.7274 90.2827 32.1124 90.2827H51.5724C51.9924 90.2827 52.3424 90.6327 52.3424 91.0527C52.3424 91.4727 51.9924 91.7877 51.5724 91.7877Z"
        fill="#0D1F27"
      />
      <path
        d="M84.0524 94.2379H64.5924C64.1724 94.2379 63.8574 93.9229 63.8574 93.5029C63.8574 93.0829 64.1724 92.7329 64.5924 92.7329H84.0524C84.4724 92.7329 84.8224 93.0829 84.8224 93.5029C84.8224 93.9229 84.4724 94.2379 84.0524 94.2379Z"
        fill="#0D1F27"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.8824 90.4928H66.1674C65.1524 90.4928 64.3474 89.6528 64.3474 88.6728V54.7228C64.3474 53.7428 65.1524 52.9028 66.1674 52.9028H81.8824C82.8974 52.9028 83.7374 53.7428 83.7374 54.7228V88.6728C83.7374 89.6528 82.8974 90.4928 81.8824 90.4928ZM66.1674 53.0778C65.2574 53.0778 64.4874 53.8128 64.4874 54.7228V88.6728C64.4874 89.5828 65.2574 90.3178 66.1674 90.3178H81.8824C82.8274 90.3178 83.5624 89.5828 83.5624 88.6728V54.7228C83.5624 53.8128 82.8274 53.0778 81.8824 53.0778H66.1674Z"
        fill="#939E9F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_5227"
          x1="89.8276"
          y1="41.8778"
          x2="89.8276"
          y2="52.5528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0F1F2" />
          <stop offset="1" stop-color="#E2E5E7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_51_5227"
          x1="87.4474"
          y1="65.0479"
          x2="99.2424"
          y2="65.0479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_51_5227"
          x1="87.4474"
          y1="66.7979"
          x2="99.2424"
          y2="66.7979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_51_5227"
          x1="87.4474"
          y1="68.5479"
          x2="99.2424"
          y2="68.5479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_51_5227"
          x1="87.4474"
          y1="70.2979"
          x2="99.2424"
          y2="70.2979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_51_5227"
          x1="110.442"
          y1="54.7928"
          x2="110.442"
          y2="76.2478"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEFEFE" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_51_5227"
          x1="29.1025"
          y1="88.0431"
          x2="21.4375"
          y2="84.7531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7B8A8B" />
          <stop offset="1" stop-color="#9FACAD" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_51_5227"
          x1="117.408"
          y1="87.6228"
          x2="111.107"
          y2="84.3328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C2C7CD" />
          <stop offset="1" stop-color="#E2E6E6" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_51_5227"
          x1="39.7425"
          y1="68.3381"
          x2="39.7425"
          y2="75.8981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C5CCCD" />
          <stop offset="1" stop-color="#9FACAD" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_51_5227"
          x1="38.7625"
          y1="63.8927"
          x2="38.7625"
          y2="69.2127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B9C2C3" />
          <stop offset="0.5" stop-color="#A1ADAE" />
          <stop offset="1" stop-color="#828E8F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_51_5227"
          x1="9.2575"
          y1="77.228"
          x2="14.9625"
          y2="77.228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C5CCCD" />
          <stop offset="1" stop-color="#9FACAD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RV;
