import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Amplify, Auth } from "aws-amplify";
import 'react-datepicker/dist/react-datepicker.css';
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import awsmobile from "./aws-exports";
import Header from "./components/header";
import { ApolloProvider } from "./context/ApolloProvider";
import { AuthContextProvider } from "./context/AuthContextProvider";
import OauthCallback from "./routes/OauthCallback";
import { BuyerLeases } from "./routes/buyerleases";
import Checkout from "./routes/checkout";
import { Earnings } from "./routes/earnings";
import { Leases } from "./routes/leases";
import { Listings } from "./routes/listings";
import { ListSpot } from "./routes/listspot";
import { CreateListingForm } from "./routes/listspot/create-listing";
import { PricingAvailabilityForm } from "./routes/listspot/step2-pricingavailability";
import { Details } from "./routes/listspot/step3-details";
import { RequirementsForm } from "./routes/listspot/step4-requirements";
import { ViewSpotTypes } from "./routes/listspot/view-spots";
import { Messages } from "./routes/messages";
import { Orders } from "./routes/orders";
import Root from "./routes/root";
import { Sell } from "./routes/sell";
import Spot from "./routes/spot";
import { Thread } from "./routes/thread";
import { Transactions } from "./routes/transactions";

import { StripeContextProvider } from "./context/StripeProvider";
import Success from "./routes/success";
import { Finalize } from "./routes/finalize";

Auth.configure(awsmobile);
Amplify.configure(awsmobile);

const Layout = () => (
  <>
    <div className="flex flex-col justify-stretch h-full">
      <div className="relative z-[10000]">
        <Header />
      </div>
      <div className="relative z-9">
        <Outlet />
      </div>
    </div>
    {/* footer */}
  </>
);

function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Root />,
        },
        {
          path: "spot/:spotId",
          element: <Spot />,
        },
        {
          path: "/checkout/:spotId",
          element: <Checkout />,
        },
        {
          path: "/checkout/:spotId/finalize",
          element: <Finalize />
        },
        {
          path: "/oauth-callback",
          element: <OauthCallback />,
        },
        {
          path: "/success/:leaseId",
          element: <Success />,
        },
        {
          path: "/rapid-success",
          element: <Success showNextSteps={false} />,
        },
        {
          path: "/sell",
          element: <Sell />,
          children: [
            {
              path: "listings",
              element: <Listings />,
            },
            {
              path: "listings/new",
              element: <CreateListingForm />,
            },
            {
              path: "listings/:listingId",
              element: <ViewSpotTypes />,
            },
            {
              path: "listings/:listingId/:spotId",
              element: <ListSpot />,
              children: [
                {
                  path: "",
                  element: <PricingAvailabilityForm />,
                },
                {
                  path: "details",
                  element: <Details />,
                },
                {
                  path: "requirements",
                  element: <RequirementsForm />,
                },
              ],
            },
            {
              path: "leases",
              element: <Leases />,
            },
            { path: "earnings", element: <Earnings />, index: false },
          ],
        },
        {
          path: "/messages",
          element: <Messages />,
        },
        {
          path: "/messages/:threadId",
          element: <Thread />,
        },
        {
          path: "orders",
          element: <Orders />,
          children: [
            {
              path: "leases",
              element: <BuyerLeases />,
              index: true,
            },
            { path: "transactions", element: <Transactions />, index: false },
          ],
        },
      ],
    },
  ]);

  return (
    <AuthContextProvider>
      <ApolloProvider>
        <StripeContextProvider>
          <RouterProvider router={router} />
        </StripeContextProvider>
      </ApolloProvider>
    </AuthContextProvider>
  );
}

export default App;
