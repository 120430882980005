import { gql } from "@apollo/client";

export const GET_BUYER_LEASES = gql`
  query LeaseDetailsQuery {
    purchasedLeases {
      lessor {
        firstName
        id
      }
      end
      start
      status
      spotSummary {
        name
      }
      payments {
        amount
        date
        fee
        net
        status
      }
      id
    }
  }
`;

export interface Lease {
  lessor: {
    firstName: string;
    id: string;
  };
  end: string;
  start: string;
  status: string;
  spotSummary: {
    name: string;
  };
  payments: [
    {
      amount: number;
      date: string;
      fee: number;
      net: number;
      status: string;
    }
  ];
  id: string;
}
