import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../../node_modules/leaflet/dist/leaflet.css";
import Map from "../map";
import { Loader } from "@googlemaps/js-api-loader";

const baseUrl = "https://maps.googleapis.com/maps/api/geocode/json";
const API_KEY = "AIzaSyC4FQ8-kbWoEX4M9ktfXcwQgugLVCImvjI";

const loader = new Loader({
  apiKey: API_KEY,
  version: "weekly",
  libraries: ["places"],
});
interface Location {
  lat: number;
  lng: number;
}

interface Result {
  geometry: {
    location: Location;
  };
}

interface ApiResponse {
  results: Result[];
  status: string;
}

interface Props {
  placeId: string;
  onChange?: (loc: { lat: number; lng: number }) => void;
}

export type GeocodeResult = google.maps.GeocoderResult;

const CoordinatePicker = ({ placeId, onChange = () => {} }: Props) => {
  const [defaultPosition, setDefaultPosition] = useState<Location>();
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

  useEffect(() => {
    loader.load().then((google) => {
      setGeocoder(new google.maps.Geocoder());
    });
  }, []);

  useEffect(() => {
    geocoder?.geocode(
      { placeId },
      (r: GeocodeResult[] | null, status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK && r) {
          const latlng = {
            lat: r[0].geometry.location.lat(),
            lng: r[0].geometry.location.lng(),
          };
          setDefaultPosition(latlng);
          onChange(latlng);
        } else {
        }
      }
    );
  }, [placeId, geocoder, onChange]);

  return (
    <div>
      {defaultPosition && (
        <Map
          draggable={true}
          still={false}
          lat={defaultPosition.lat}
          lng={defaultPosition.lng}
          onFinishDrag={onChange}
        />
      )}
    </div>
  );
};

export default CoordinatePicker;
