import { ReactNode } from "react";

export type Props = {
  columnNames: string[];
  loading?: boolean;
  children: ReactNode;
};

export const Table = ({ columnNames, loading = false, children }: Props) => {
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead className="bg-gray-100">
          <tr className="text-xs text-left leading-4 font-medium text-gray-700 tracking-wider">
            {columnNames.map((col) => (
              <th className="px-6 py-7">{col}</th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {loading ? (
            // Skeleton loader
            <tr className="animate-pulse">
              <td colSpan={columnNames.length} className="px-6 py-4">
                <div className="bg-gray-200 h-5 rounded-md"></div>
              </td>
            </tr>
          ) : (
            // Render data rows
            children
          )}
        </tbody>
      </table>
    </div>
  );
};

export type RowProps = {
  values: ReactNode[];
};

export const Row = ({ values }: RowProps) => {
  return (
    <tr className="text-sm leading-5 text-gray-500">
      {values.map((value) => (
        <td className="px-6 py-4 whitespace-no-wrap">{value}</td>
      ))}
    </tr>
  );
};
