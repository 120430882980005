import { useQuery, useMutation } from "@apollo/client";
import { gql } from "../../__generated__";
import { Link, useParams } from "react-router-dom";
import Map from "../../components/map";
import { Row, Table } from "../../components/table";
import Button from "../../components/button";
import { BsFillTrash3Fill } from "react-icons/bs";
import Switch from "react-switch";
import { SpotStatus } from "../../__generated__/graphql";

interface Spot {
  id: string;
  name: string;
  numberOfSpaces: number;
  status: string;
  property: {
    position: {
      lat: number;
      lng: number;
    };
  };
}

const LISTINGS_QUERY = gql(`
query Properties {
  properties {
    address
    countPendingLeases
    countSpotTypes
    id
    name
    status
    position {
      lat
      lng
    }
  }
}
`);

const GET_SPOT_TYPES = gql(`
  query GetSpotTypesQuery($propertyId: ID) {
    mySpots(propertyId: $propertyId) {
      id
      name
      numberOfSpaces
      status
      property {
        position {
          lat
          lng
        }
      }
    }
  }
`);

export const ViewSpotTypes = () => {
  const { listingId } = useParams();

  const { data: listings } = useQuery(LISTINGS_QUERY);

  const [updateListing] = useMutation(
    gql(`mutation ChangeSpotStatus($updateSpotId: ID!, $data: SpotData!) {
      updateSpot(id: $updateSpotId, data: $data) {
      spot {
        id
        status
      }
      success
    }
  }`)
  );

  const [deleteListing, { loading: deleting }] = useMutation(
    gql(`mutation DeleteSpot($deleteSpotId: ID!) {
      deleteSpot(id: $deleteSpotId) {
        success
      }
    }`),
    {
      refetchQueries: ["GetSpotTypesQuery"],
    }
  );

  const { loading, error, data } = useQuery<{ mySpots: Spot[] }>(
    GET_SPOT_TYPES,
    {
      variables: {
        propertyId: listingId,
      },
    }
  );

  const property = listings?.properties?.find((p) => p?.id === listingId);

  return (
    <div className={"bg-white shadow-md p-4 rounded-lg max-w-xl m-auto"}>
      <h1 className={"font-bold text-2xl text-center mb-4"}>
        {property?.name ?? "Loading..."}
      </h1>
      <Map lat={property?.position?.lat} lng={property?.position?.lat} />
      <div className={"flex row items-center justify-between mt-4"}>
        <p className={"font-semibold text-md"}>Spot Types</p>
        {/* Spot Type Form Not Working */}
        {/* <Link to={"new"}>
          <Button type="tertiary" text="+ Spot Type" />
        </Link> */}
      </div>
      <Table columnNames={["Spot Type Name", "", "Total Spots", ""]}>
        {data?.mySpots.map((spot) => (
          <Row
            key={spot.id}
            values={[
              spot.name, // Temporarily Disabling Link as List Spot Flow is WIP
              // <Link to={spot.id}>{spot.name}</Link>,
              <Switch
                onChange={(checked) => {
                  updateListing({
                    variables: {
                      updateSpotId: spot.id,
                      data: {
                        status: checked ? SpotStatus.Available : SpotStatus.Disabled,
                      },
                    },
                    optimisticResponse: {
                      updateSpot: {
                        success: true,
                        __typename: "SpotMutationResponse",
                        spot: {
                          __typename: "Spot",
                          id: spot.id,
                          status: checked ? SpotStatus.Available : SpotStatus.Disabled,
                        },
                      },
                    },
                  });
                }}
                checked={spot.status === "AVAILABLE"}
              />,
              spot.numberOfSpaces,
              <button
                onClick={() => {
                  deleteListing({
                    variables: { deleteSpotId: spot.id },
                  });
                }}
              >
                <BsFillTrash3Fill />
              </button>,
            ]}
          ></Row>
        ))}
      </Table>
      {loading && <p className="mt-3 font-semibold">Loading...</p>}
    </div>
  );
};
