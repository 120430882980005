import HouseIcon from "./house";
import ApartmentIcon from "./apartment";
import GarageIcon from "./garage";
import LotIcon from "./lot";
import CompactIcon from "./Compact";
import PickupIcon from "./Pickup";
import RVIcon from "./RV";
import SedanIcon from "./Sedan";
import SUVIcon from "./SUV";
import VanIcon from "./Van";

export const House = HouseIcon;
export const Apartment = ApartmentIcon;
export const Garage = GarageIcon;
export const Lot = LotIcon;
export const Compact = CompactIcon;
export const Pickup = PickupIcon;
export const RV = RVIcon;
export const Sedan = SedanIcon;
export const SUV = SUVIcon;
export const Van = VanIcon;
