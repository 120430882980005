import { gql } from "@apollo/client";

export const CREATE_THREAD = gql`
  mutation CreateThread($userId: ID!, $message: String!) {
    createThread(userId: $userId, message: $message) {
      code
      success
      message
      sentMessage {
        id
        timestamp
        text
        type
        authorId
        threadId
      }
    }
  }
`;

export interface CreateThreadResponse {
  code: string;
  message: string;
  sentMessage: {
    authorId: string;
    id: string;
    text: string;
    threadId: string;
    timestamp: string;
    type: number;
  };
  success: boolean;
}
