import React from "react";
import { IoMdClose , IoMdArrowBack} from "react-icons/io";
import { forwardRef } from "react";
import {useState} from "react"

export type Props = {
     onClick: React.MouseEventHandler<HTMLButtonElement>;
     boldtext? : string;
     arrow? : boolean;
     onArrowClick? : React.MouseEventHandler<HTMLButtonElement>;
     popupSize? : string;

}

export type Ref = HTMLButtonElement;

export default forwardRef<Ref, Props>(function PopupHeader(props, ref) {

    const [focus, setFocus] = useState(false);
    const [focus2, setFocus2] = useState(false);

    const {
        onClick, 
        boldtext, 
        arrow = false, 
        popupSize = "large",
        onArrowClick
      } = props;
    return (
        <div className = "flex flex-col text-center items-center">
            <img  className={`${popupSize === "large" && "mt-[5vh]"}  
            ${popupSize === "medium" && "mt-[15vh]"} 
            ${popupSize === "small" && "mt-[25vh]"} 
            sm:mt-[2vh] self-center w-[12vmin] h-[12vmin] sm:w-[6vmin] sm:h-[6vmin]`} src= "/icon.png" alt="Icon"></img>
            <button ref = {ref} onFocus = {()=> setFocus(true)}  onBlur = {()=> setFocus(false)} className=" bg-transparent border-none p-0 cursor-pointer " onClick={onClick}>
                <IoMdClose className= {`${focus? "border-[0.1vmin]" : "border-none"} border-blue-500 text-gray-500 absolute top-10 sm:top-6 right-6  w-6 h-6 cursor-pointer hover:border-[0.1vmin]`}></IoMdClose>
            </button>
            { arrow && (
            <button onFocus = {()=> setFocus2(true)}  onBlur = {()=> setFocus2(false)} className="bg-transparent border-none p-0 cursor-pointer" onClick={onArrowClick}>
                <IoMdArrowBack className= {`${focus2 ? "border-[0.1vmin]" : "border-none"} border-blue-500 text-gray-500 absolute top-10 sm:top-6 left-6 w-6 h-6 cursor-pointer hover:border-[0.1vmin]`}></IoMdArrowBack>
            </button>
            )}
            <h2 className={`font-poppins font-bold text-[7.5vmin] sm:text-[3.5vmin] mt-[2vh] mb-[1vh] leading-[1.2]
        ${popupSize === "small" && "ml-5 mr-5"}
        `}>{boldtext}</h2>
        </div>
        
    );
})

