import { useMemo, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { gql } from "../__generated__";
import { Spot as SpotComponent } from "../components/spot";
import { SearchQueryResults } from "../components/searchqueryresults";

import PurchaseBar from "../components/purchasebar";

const GET_SPOT = gql(`query GetSpotPageDataQuery($spotId: ID!) {
  spot(id: $spotId) {
    id
    name
    position {
      lat
      lng
    }
  }
}`);

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Spot = () => {
  const { spotId } = useParams();
  
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);

  const { data } = useQuery(GET_SPOT);

  useEffect(() => {
    document.title =
      data != null
        ? `${data?.spot?.name ?? "Unnamed Spot"} | BuyMySpot`
        : "BuyMySpot | Find a Parking Spot";
  }, [data]);

  if (spotId == null) return;
  //const params = useQueryParams();

  /*
  if (error) {
    return <p>{JSON.stringify(error)}</p>;
  }

  if (loading) {
    return (
      <div className="mx-auto max-w-3xl  lg:bg-white lg:drop-shadow-md lg:my-5 lg:rounded-xl lg:overflow-hidden">
        <div className="h-56 w-full animate-pulse bg-gray-200"></div>
        <div className="h-14 w-2/3 bg-gray-200 mt-8 mx-4"></div>
        <div className="h-6 bg-gray-200 mt-4 mx-4"></div>
        <div className="h-6 bg-gray-200 mt-4 mx-4"></div>
        <div className="h-6 w-1/3 bg-gray-200 mt-4 mx-4"></div>
        <div className="flex justify-around mt-8">
          <div className="h-16 w-1/6 bg-gray-200 mt-4 mx-4"></div>
          <div className="h-16 w-1/6 bg-gray-200 mt-4 mx-4"></div>
          <div className="h-16 w-1/6 bg-gray-200 mt-4 mx-4"></div>
        </div>
        <div className="h-56 animate-pulse bg-gray-200 mt-8 mx-4"></div>
      </div>
    );
  }
*/
  // const start = new Date(params.get("start") ?? data?.spot.period.start ?? "");
  // const end = new Date(params.get("end") ?? data?.spot.period.end ?? "");

  return (
    <div className="flex flex-col lg:flex-row container mx-auto gap-4 lg:my-5 max-w-7xl lg:px-4">
      <div className="flex-1 lg:bg-white lg:drop-shadow-md lg:rounded-xl lg:overflow-hidden">
        <SpotComponent id={spotId} datepickerClassName="lg:hidden relative z-[1000000000000]" start={start} end={end} onStartChange={setStart} onEndChange={setEnd} />
      </div>
      <div className="min-w-[400px] px-3">
        <div className="fixed lg:relative lg:mb-12 bg-bms-fill-regular left-0 bottom-0  w-full z-[1000000000] rounded-md px-2 py-3 shadow-[0_-2px_4px_0_rgba(0,0,0,0.1)] lg:shadow-md">
          <PurchaseBar start={start} end={end} onStartChange={setStart} onEndChange={setEnd} spotId={spotId ?? ""}  />
        </div>
        <h3 className="font-semibold text-xl mb-3">You may also consider</h3>
        <SearchQueryResults
          query={{
            location: {
              lat: data?.spot?.position.lat ?? 42.27928267921247,
              lng: data?.spot?.position.lng ?? -83.74421324458177,
            },
          }}
          limit={3}
        />
      </div>
    </div>
  );
};

export default Spot;
