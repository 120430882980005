import { gql } from "@apollo/client";

export const GET_AVAILABLE_SPOTS = gql`
  query GetAvailableSpotsQuery {
    spots {
      id
      name
      minimumLeaseRange
      pricing {
        daily
        weekly
        monthly
        lease
      }
      upfrontOnly
      images {
        imageId
        href
      }
      position {
        lat
        lng
      }
      period {
        start
        end
      }
      spotType
    }
  }
`;

export interface Spot {
  id: string;
  name: string;
  address: string;
  description: string;
  minimumLeaseRange: number;
  pricing: {
    daily: number;
    weekly: number;
    monthly: number;
    lease: number;
  };
  upfrontOnly: boolean;
  period: {
    start: string;
    end: string;
    fullRange: boolean;
  };
  images: [
    {
      imageId: string;
      href: string;
    }
  ];
  position: {
    lng: number;
    lat: number;
  };
  carType: number;
  spotType: number;
  sheltered: boolean;
  parkingPass: boolean;
  restroom: boolean;
  tailgating: boolean;
}
