import { Table, Row } from "../components/table";
import { useQuery } from "@apollo/client";
import { GET_BUYER_LEASES, Lease } from "../queries/getBuyerLeasesQuery";
import { useSearchParams } from "react-router-dom";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import Profile from "../components/profile";
import React from "react";

const friendlyDate = (date: string) =>
  new Date(date).toLocaleDateString("en-us");

function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Transactions = () => {
  let query = useQueryParams();
  const id = query.get("id");

  const { loading, error, data } = useQuery<{ purchasedLeases: Lease[] }>(
    GET_BUYER_LEASES
  );

  const transactions = data?.purchasedLeases
    .filter((lease) => ["APPROVED", "PENDING"].includes(lease.status))
    .filter((t) => (id == null ? true : id === t.id))
    .reduce(
      (prev, current) => [
        ...prev,
        ...current.payments.map((t) => ({
          ...t,
          name: current.spotSummary.name,
          lessor: current.lessor,
        })),
      ],
      [] as {
        amount: number;
        date: string;
        fee: number;
        net: number;
        status: string;
        name: string;
        lessor: {
          firstName: string;
          id: string;
        }
      }[]
    )
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  console.log(data);

  return (
    <>
      <h1 className="font-bold text-2xl mb-6">Transactions</h1>
      <div className="bg-white p-4 rounded-md">
        <Table
          loading={loading}
          columnNames={["Date", "Lessor", "Listing", "Amount", "Status"]}
        >
          {transactions?.map((t) => (
            <Row
              values={[
                friendlyDate(t.date),
                <Profile name={t.lessor.firstName} id={t.lessor.id} />,
                t.name,
                `$${t.net.toFixed(2)}`,
                transactionStatus(t.status),
              ]}
            />
          ))}
        </Table>
      </div>
    </>
  );
};

const transactionStatus = (t: string) => {
  switch (t) {
    case "INACTIVE":
      return "Inactive"; // Can differentiate between Cancelled vs. Awaiting Lease Approval
    case "PENDING":
      return "Upcoming";
    case "PROCESSING":
      return "Processing";
    case "SUCCESS":
      return "Received";
    case "FAILED":
      return "Failed";
    case "CANCELLED":
      return "Cancelled";
    default:
      return "Unknown";
  }
};
