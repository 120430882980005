import FormActions from "../../components/formactions";
import ImageRadio, {
  ImageRadioGroup,
} from "../../components/image-radio/image-radio";
import { Compact, Pickup, RV, SUV, Sedan, Van } from "../../svg";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { FormContext } from "./FormContext";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { gql } from "../../__generated__";

interface SpotData {
  carType: number;
  description: string;
  evCharging: boolean;
  images?: string[];
  minimumLeaseRange: number;
  nickname: string;
  numberOfSpaces: number;
  parkingPass: boolean;
  period: {
    start: string;
    end: string;
  };
  pricing: {
    daily: number;
    weekly: number;
    monthly: number;
  };
  propertyId: string;
  restroom: boolean;
  securityCameras: boolean;
  sheltered: boolean;
  status: string;
  tailgating: boolean;
  upfrontOnly?: boolean;
  prorate: boolean;
}

interface SpotCreateResponse {
  code: string;
  message: string;
  success: boolean;
}

const LIST_SPOT = gql(`
  mutation CreateSpot($data: SpotData!) {
    createSpot(data: $data) {
      code
      message
      success
    }
  }
`);

const UPDATE_SPOT = gql(`
  mutation UpdateSpot($id: ID!, $data: SpotData!) {
    updateSpot(id: $id, data: $data) {
      code
      message
      success
    }
  }
`);

const schema = yup.object({
  carType: yup.number().required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

export const RequirementsForm = () => {
  let [formData, setFormData] = useContext(FormContext);

  const { spotId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      carType: formData.carType,
    },
  });

  const navigate = useNavigate();

  const [listSpot, { loading, error, data }] = useMutation<
    { createSpot: SpotCreateResponse },
    { data: SpotData }
  >(LIST_SPOT);

  const [
    updateSpot,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(UPDATE_SPOT);

  const onSubmit = handleSubmit((data) => {
    console.log(formData.image);
    const newFormData = {
      ...formData,
      carType: data.carType,
    };
    setFormData(newFormData);

    const imageParts = formData.image?.split("/"); //changed to question mark as if no image was 
    //put in then sumbission of the spot would cause an error (cannot split on an undefined)

    if (spotId == "new") {
      listSpot({
        variables: {
          data: {
            carType: Number(newFormData.carType!),
            description: formData.description!,
            evCharging: formData.evCharging!,
            images: imageParts?.length > 0 ? [imageParts?.pop()!] : undefined,
            minimumLeaseRange: Number(formData.minimumLeaseRange!),
            nickname: formData.nickname!,
            numberOfSpaces: Number(formData.numberOfSpaces!),
            parkingPass: formData.parkingPass!,
            period: {
              start: new Date(formData.period?.start!).toISOString(),
              end: new Date(formData.period?.end!).toISOString(),
            },
            pricing: {
              daily: Number(formData.pricing!.daily),
              weekly: Number(formData.pricing!.weekly),
              monthly: Number(formData.pricing!.monthly),
            },
            propertyId: formData.propertyId!,
            restroom: formData.restroomAccess!,
            securityCameras: formData.securityCameras!,
            sheltered: formData.sheltered!,
            status: "AVAILABLE",
            tailgating: formData.tailgatingPermitted!,
            upfrontOnly: formData.upfrontOnly!,
            prorate: formData.prorate!
          },
        },
        onCompleted: (data) => {
          alert("Spot has been listed");

          navigate("../../listings");
        },
      });
    } else {
      if (spotId == null) return;
      updateSpot({
        variables: {
          id: spotId,
          data: {
            carType: Number(newFormData.carType!),
            description: formData.description!,
            evCharging: formData.evCharging!,
            images: imageParts?.length > 0 ? [imageParts?.pop()!] : undefined,
            minimumLeaseRange: Number(formData.minimumLeaseRange!),
            nickname: formData.nickname!,
            numberOfSpaces: Number(formData.numberOfSpaces!),
            parkingPass: formData.parkingPass!,
            period: {
              start: new Date(formData.period?.start!).toISOString(),
              end: new Date(formData.period?.end!).toISOString(),
            },
            pricing: {
              daily: Number(formData.pricing!.daily),
              weekly: Number(formData.pricing!.weekly),
              monthly: Number(formData.pricing!.monthly),
            },
            propertyId: formData.propertyId!,
            restroom: formData.restroomAccess!,
            securityCameras: formData.securityCameras!,
            sheltered: formData.sheltered!,
            tailgating: formData.tailgatingPermitted!,
            //upfrontOnly: ,
          },
        },
        onCompleted: (data) => {
          alert("Spot has been listed");

          navigate("../../listings");
        },
      });
    }
  });

  return (
    <div>
      <div className = "text-center text-2xl font-semibold mt-[1vh] ml-3 mr-3">
        Spot Size
      </div>
      <form onSubmit={onSubmit} className = "mt-[2vh]">
        <ImageRadioGroup>
          <ImageRadio
            {...register("carType", { required: true })}
            text={"Compact"}
            icon={<Compact />}
            value={0}
            defaultChecked={formData.carType === 0}
          />
          <ImageRadio
            {...register("carType", { required: true })}
            text={"Sedan"}
            icon={<Sedan />}
            value={1}
            defaultChecked={formData.carType === 1}
          />
          <ImageRadio
            {...register("carType", { required: true })}
            text={"SUV"}
            icon={<SUV />}
            value={2}
            defaultChecked={formData.carType === 2}
          />
          <ImageRadio
            {...register("carType", { required: true })}
            text={"Pickup"}
            icon={<Pickup />}
            value={3}
            defaultChecked={formData.carType === 3}
          />
          <ImageRadio
            {...register("carType", { required: true })}
            text={"Van"}
            icon={<Van />}
            value={4}
            defaultChecked={formData.carType === 4}
          />
          <ImageRadio
            {...register("carType", { required: true })}
            text={"RV"}
            icon={<RV />}
            value={5}
            defaultChecked={formData.carType === 5}
          />
        </ImageRadioGroup>
        <FormActions
          submitText="Submit"
          submitDisabled={!isValid || loading}
          submitLoading={loading}
        />
      </form>
    </div>
  );
};
