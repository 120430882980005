import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import {useForm, SubmitHandler} from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { IoMdClose} from 'react-icons/io';
import { useState, useEffect } from "react";
import  Popup  from "./login/popup";
import PopupHeader from "./login/popupheader";
import Textfield from "./textfield/textfield";
import Submitbutton from "./login/submitbutton";
import ProgressBar from "./login/progressbar";
import FocusTrap from "focus-trap-react"

export type Props = {
    password: string;
    confirmpassword : string;
    pageChanger : React.Dispatch<React.SetStateAction<string>>;
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;
    mail  :string;
    code : string;
}

const ChangePassword = ({ confirmpassword, password, pageChanger, setLoginShown, code, mail }: Props) => {
    const [anerror, setError] = useState(false);
    const [anerror2, setError2] = useState(false);
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = useContext(AuthContext);
    const {register, handleSubmit, reset, formState:{errors}} = useForm<Props>({defaultValues: {
        confirmpassword : "",
        password : ""
    }});

    const hide = () => {
        handleLeave();
        reset();
        setLoginShown(false);
    }

    useEffect(() => {
        const handleKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            hide();
          }
        };
    
        window.addEventListener('keydown', handleKey);
    
        return () => {
          window.removeEventListener('keydown', handleKey);
        };
      }, [hide]);

    
    const handleLeave = () => {
        setError(false);
        setError2(false);
        setMessage('');
        setMessage2('');
        
    }

    
    const onSubmit : SubmitHandler<Props> = async data => {
        handleLeave();
        setLoading(true);
        if (data.confirmpassword !== data.password){
            setError2(true);
            setMessage2("Passwords do not match.")
            setLoading(false);
        }
        else {
            try {
                await Auth.forgotPasswordSubmit(mail, code, data.password);
                pageChanger("SuccessfullyChanged");
                reset();
            }
            catch (error) {
                console.log(error);
                if (error.message === "Attempt limit exceeded, please try after some time."){
                    setError2(true); //underneath confirm password
                    setMessage2("Attempt limit exceeded. Please try again later or contact support.")
                }
                if (error.message === "Password does not conform to policy: Password must have lowercase characters"){
                    setMessage("Password must contain lowercase letters.");
                    setError(true);
                }
                if (error.message === "Password does not conform to policy: Password must have uppercase characters"){
                    setMessage("Password must contain uppercase letters.");
                    console.log(message);
                    setError(true);
                }
                if (error.message === "Password does not conform to policy: Password must have numeric characters"){
                    setMessage("Password must contain a number.");
                    setError(true);
                }
                if (error.message === "Password does not conform to policy: Password must have symbol characters"){
                    setMessage("Password must contain a symbol.");
                    setError(true);
                }
                if (error.message === "Password does not conform to policy: Password not long enough") {
                    setMessage("Password must be 8 characters.");
                    setError(true);
                }
            }
            finally {
                setLoading(false);
            }
        }
    }

    return (
        <div>
            <Popup height = "60">
                <FocusTrap active = {true} focusTrapOptions={{clickOutsideDeactivates : false, allowOutsideClick : false}}>
                <div>
                <PopupHeader popupSize = "medium" onClick = {hide} boldtext = "Create New Password" ></PopupHeader>
                <div className = "mt-[1vh] sm:mt-[2vh]"></div>
                <span className="text-1xl mx-auto w-4/5 font-poppins mb-[2vh] text-center "> Please enter a new password below </span>
                <form noValidate = {true} onSubmit = {handleSubmit(onSubmit)} className="!top-12">
                    <Textfield 
                    type = {"password"}
                    {...register("password", { required:  true})}
                        label="New Password" 
                        placeholder="Enter your new password" 
                        error = {anerror} validationMessage= {message}
                        />
                    <Textfield 
                    type = {"password"}
                    {...register("confirmpassword", { required:  true})}
                        label="Confirm New Password" 
                        placeholder="Confirm your new password" 
                        error = {anerror2} validationMessage= {message2}
                        />
                    <div className = "mb-[5vh] sm:mt-[0vh]"></div>
                    <Submitbutton text = "Change Password" loading = {loading}/>
                   
                </form>
                </div>
                </FocusTrap>

            </Popup>
        </div>
    )
}


export default ChangePassword;