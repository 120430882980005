import { createContext } from "react";

export interface FormData {
  propertyId?: string;
  numberOfSpaces?: number;
  period?: {
    start?: Date;
    end?: Date;
  };
  minimumLeaseRange?: number;
  pricing?: {
    daily?: number;
    weekly?: number;
    monthly?: number;
  };
  nickname?: string;
  description?: string;
  sheltered?: boolean;
  parkingPass?: boolean;
  securityCameras?: boolean;
  evCharging?: boolean;
  tailgatingPermitted?: boolean;
  restroomAccess?: boolean;
  carType?: number;
  image?: string;
  upfrontOnly?: boolean;
  prorate?: boolean;
}

export const FormContext = createContext<
  [FormData, React.Dispatch<React.SetStateAction<FormData>>]
>([{}, () => {}]);
