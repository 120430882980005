import { BsFillCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Button from "../components/button";

interface Props {
  showNextSteps?: boolean;
}

const Success = ({ showNextSteps = true }: Props) => {
  return (
    <div className="container py-10 mx-auto">
      <div className="text-bmsblue flex justify-center items-center">
        <BsFillCheckCircleFill style={{ width: "150px", height: "150px" }} />
      </div>
      <br></br>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className="text-3xl font-semibold">
          {" "}
          Success! Your order is complete!{" "}
        </h1>
      </div>
      {showNextSteps && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Congrats on buying your spot! You'll receive an email confirmation
            shortly.
          </div>

          <div className="mx-auto max-w-xl mt-10 p-5 border-gray-300 border rounded-2xl">
            <h1 className="font-bold text-center text-lg mb-2">What's next?</h1>
            <ul className="list-disc list-inside">
              <li>The spot owner now has to approve your lease</li>
              <li>Once your lease is responded to, you'll receive an email</li>
              {/* Commented Out Mobile App Link */}
              {/* <li>
                Download the{" "}
                <a href="https://onelink.to/gjr88j" className="text-bmsblue">
                  BuyMySpot Mobile App
                </a>{" "}
                to get instant notifications when your lease has updates
              </li> */}
              <li>
                If you change your mind, you can cancel your lease until it is
                approved.
              </li>
              <li>
                To View/Manage your lease, go to the{" "}
                <Link className="text-bmsblue" to="../orders">
                  Orders page
                </Link>
              </li>
            </ul>
          </div>

          <br />
          <br />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
              }}
            >
              <Button type="tertiary" url="/" text="Back to Homepage" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Success;
