import { gql, useQuery, useMutation } from "@apollo/client";
import { Row, Table } from "../components/table";
import { Link } from "react-router-dom";
import Button from "../components/button";
import Switch from "react-switch";
import { BsFillTrash3Fill } from "react-icons/bs";

interface Listing {
  address: string;
  countSpotTypes: number;
  countPendingLeases: number;
  id: string;
  name: string;
  status: string;
}

const LISTINGS_QUERY = gql`
  query Properties {
    properties {
      address
      countPendingLeases
      countSpotTypes
      id
      name
      status
      position {
        lat
        lng
      }
    }
  }
`;

export const Listings = () => {
  const { loading, error, data } = useQuery<{ properties: Listing[] }>(
    LISTINGS_QUERY
  );

  const [updateListing] = useMutation(
    gql(`mutation UpdateProperty($updatePropertyId: ID!, $data: PropertyUpdateData!) {
      updateProperty(id: $updatePropertyId, data: $data) {
        success
        property {
          id
          status
        }
      }
    }`)
  );

  const [deleteListing, { loading: deleting }] = useMutation(
    gql(`mutation DeleteProperty($deletePropertyId: ID!) {
      deleteProperty(id: $deletePropertyId) {
        success
        message
        code
      }
    }`),
    {
      refetchQueries: ["Properties"],
    }
  );

  const handleClick = () => {
    console.log(data);
  };

  return (
    <>
      <div className="flex justify-between align-center row">
        <h1 className="font-bold text-2xl mb-6">Listings</h1>
        {/* <Button text = "Test for Data" onClick={handleClick}/> */}
        {/*  Disabled Listing Button (Not Working / WIP) */}
        {/* <Link to={"new"}>
          <Button type="primary" text="+ Listing" />
        </Link> */}
      </div>
      <div className="bg-white p-4 rounded-md">
        <Table
          columnNames={[
            "Listing",
            // "",
            // "Pending Leases",
            // "Total Occupied",
            // "Total Available",
            // "Total Spots",
            // "",
            // "Last Updated",
          ]}
          loading={false}
        >
          {data?.properties?.map((listing) => (
            <Row
              values={[
                <Link to={`${listing.id}`} className="w-100 block">
                  {listing.name}
                </Link>,
                // Disabled the Disabled Listing Button
                // <Switch
                //   onChange={(checked) => {
                //     updateListing({
                //       variables: {
                //         updatePropertyId: listing.id,
                //         data: {
                //           status: checked ? "AVAILABLE" : "DISABLED",
                //         },
                //       },
                //       optimisticResponse: {
                //         updateProperty: {
                //           success: true,
                //           __typename: "PropertyMutationResponse",
                //           property: {
                //             __typename: "Property",
                //             id: listing.id,
                //             status: checked ? "AVAILABLE" : "DISABLED",
                //           },
                //         },
                //       },
                //     });
                //   }}
                //   checked={listing.status === "AVAILABLE"}
                // />,
                // listing.countPendingLeases, //for pendingLeases, disabled (featured WIP)
                // listing.countOccupiedSpots,
                // listing.countAvailableSpots,
                // listing.countSpotTypes,
                // Disabled the Delete Listing Button
                // <button
                //   onClick={() => {
                //     deleteListing({
                //       variables: { deletePropertyId: listing.id },
                //     });
                //   }}
                // >
                //   <BsFillTrash3Fill />
                // </button>,
              ]}
            />
          ))}
        </Table>
      </div>
    </>
  );
};
