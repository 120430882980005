import React, { useState, useEffect } from "react";
import { gql } from "../__generated__";
import { useMutation } from "@apollo/client";

const addSpotImageMutation = gql(`
mutation AddSpotImage {
  addSpotImage {
    code
    success
    message
    imageId
    uploadUrl
    destinationUrl
  }
}
`);

interface Props {
  defaultImage?: string;
  onChange?: (img: string) => void;
}

const ImageUploader = ({ defaultImage, onChange = () => {} }: Props) => {
  const [image, setImage] = useState<string | null>(defaultImage ?? null);
  const [isLoading, setIsLoading] = useState(false);

  const [addSpotImage, { loading, error, data }] =
    useMutation(addSpotImageMutation);

  useEffect(() => {
    if (image == null) return;
    onChange(image);
  }, [image, onChange]);

  const handleDrop: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    uploadImage(file);
  };

  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files == null) return;

    const file = e.target.files[0];
    uploadImage(file);
  };

  const uploadImage = (file: File) => {
    setIsLoading(true);

    addSpotImage({
      async onCompleted(data, clientOptions) {
        if (data.addSpotImage == null) return;

        const { uploadUrl, destinationUrl } = data.addSpotImage;

        if (uploadUrl == null || destinationUrl == null) return;

        const resp = await fetch(uploadUrl, {
          headers: {
            //Accept: "application/json",
            "Content-Type": file.type,
          },
          method: "PUT",
          body: file,
        });

        setImage(destinationUrl);
        setIsLoading(false);
      },
    });
    /*const reader = new FileReader();

    reader.onload = () => {
      setIsLoading(false);
      setImage(reader.result);
    };

    reader.readAsDataURL(file);*/
  };

  return (
    <div
      className={`image-uploader ${
        isLoading ? "loading" : ""
      } p-4 border-2 border-dashed border-gray-300 rounded-md`}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      {image ? (
        <img
          className="uploaded-image h-48 w-full object-cover rounded-md"
          src={image}
          alt="Uploaded"
        />
      ) : (
        <div className="drop-box text-center">
          <span className="text-gray-500">
            Drag and drop an image or click here to upload
          </span>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
        </div>
      )}
      {isLoading && (
        <div className="loading-spinner mt-2 text-center text-gray-500">
          Loading...
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
