import React, { useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { tokenFromAuthorizationCode } from "../helpers/CognitoConnector";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OauthCallback = () => {
  let query = useQuery();
  const auth = useContext(AuthContext);

  const code = query.get("code");
  const state = query.get("state");

  useEffect(() => {
    (async () => {
      if (code == null) {
        // redirect to home page or some shit
        return;
      }

      const loginResponse = await tokenFromAuthorizationCode(code);

      auth.login(loginResponse);

      window.location.replace(state ?? "/");

      // redirect here
    })();
  }, [auth, code, state]);

  return (
    <div className="text-center py-10">
      <h1 className="text-3xl font-bold">You're being logged in</h1>
      <p className="mt-4">You'll be redirected momentarily</p>
    </div>
  );
};

export default OauthCallback;
