import { AuthContext } from "../context/AuthContext";
import { useContext, useEffect } from "react";
import {useForm, SubmitHandler} from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { UsernamePasswordOpts } from "@aws-amplify/auth/lib-esm/types";
import { IoMdClose} from 'react-icons/io';
import Popup from "./login/popup";
import Popupheader from "./login/popupheader";
import Submitbutton from "./login/submitbutton";
import FocusTrap from "focus-trap-react";

export type Props = {
    email: string | UsernamePasswordOpts;
    password?: string;
    pageChanger : React.Dispatch<React.SetStateAction<string>>;
    setLoginShown : React.Dispatch<React.SetStateAction<boolean>>;
}

const SuccessfullyCreated = ({ email, password, pageChanger, setLoginShown }: Props) => {

    const auth = useContext(AuthContext);
    const {register, handleSubmit, formState:{errors}} = useForm<Props>({defaultValues: {
        email : "", 
        password : ""
    }});
    const handleLogin = () => {
        pageChanger("Login");
    }
    

    const hide = () => setLoginShown(false);
    useEffect(() => {
        const handleKey = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            hide();
          }
        };
    
        window.addEventListener('keydown', handleKey);
    
        return () => {
          window.removeEventListener('keydown', handleKey);
        };
      }, [hide]);



    return (
        <div>
            <Popup height = "40">
                <FocusTrap active = {true} focusTrapOptions={{clickOutsideDeactivates : false, allowOutsideClick : false}}>
                <div>
                <Popupheader popupSize = "small" boldtext = "Your account has been successfully created" onClick={hide}></Popupheader>
                <Submitbutton text= "Log In" onClick={handleLogin}/>
                </div>
                </FocusTrap>
    
            </Popup>
        </div>
    )
}


export default SuccessfullyCreated;