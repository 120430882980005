import { useContext, useEffect, useMemo, useState } from "react";
import "../../node_modules/leaflet/dist/leaflet.css";
import { AuthContext } from "../context/AuthContext";

import { useLocation } from "react-router-dom";
import { Search, SearchQuery } from "../components/search";
import { SearchQueryMap } from "../components/searchquerymap";
import { SearchQueryResults } from "../components/searchqueryresults";

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Root = () => {
  const params = useQueryParams();

  const auth = useContext(AuthContext);

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({});

  useEffect(() => {
    document.title = "BuyMySpot | Find a Parking Spot";
  }, []);

  const start = params.get("start");
  const end = params.get("end");
  const lng = params.get("lng");
  const lat = params.get("lat");

  // grab url params and put in search query object
  useEffect(() => {
    if (start && end)
      setSearchQuery((q) => {
        return { ...q, dates: { start: new Date(start), end: new Date(end) } };
      });
    if (lng && lat)
      setSearchQuery((q) => {
        return { ...q, location: { lng: Number(lng), lat: Number(lat) } };
      });
  }, [start, end, lng, lat]);

  return (
    <div className="h-[calc(100vh-theme(spacing.16))] overflow-hidden flex-1 bg-bms-fill-regular">
      {true && (
        <div className="h-full flex flex-col md:flex-row justify-stretch items-stretch">
          {/* Sidebar */}
          <div className="max-w-lg container mx-auto px-4 h-full overflow-auto">
            <div className="h-full flex flex-col justify-stretch">
              {/* List View */}
              <div className="flex-1">
                <div className="mt-5">
                  <Search onQueryChange={setSearchQuery} />
                </div>
                <div className="my-6">
                  <SearchQueryResults query={searchQuery} />
                </div>
              </div>
            </div>
          </div>
          <div className="h-full flex-1">
           <SearchQueryMap query={searchQuery} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Root;
