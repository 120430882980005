import { ReactNode, forwardRef } from "react";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  text: string;
  icon: ReactNode;
}

export type Ref = HTMLInputElement;

export default forwardRef<Ref, Props>(function ImageRadio(props, ref) {
  const { text, icon, ...inputProps } = props;

  return (
    <label className="group relative flex-1">
      <input
        {...inputProps}
        ref={ref}
        type="radio"
        className="peer opacity-0 width-0 absolute"
      />
      <div
        className={`hover:text-bmsblue transition-all grayscale text-gray-600 peer-checked:grayscale-0 peer-checked:text-bmsblue`}
      >
        <p className="text-center mb-1 font-semibold">{text}</p>
        <div className="aspect-square group-hover:outline group-focus:outline outline-2 outline-bmsblue bg-lightBlue flex justify-center items-center rounded-lg p-2">
          {icon}
        </div>
      </div>
    </label>
  );
});

export type GroupProps = {
  children: ReactNode;
};

export const ImageRadioGroup = <T extends unknown>({
  children,
}: GroupProps) => {
  return (
    <div className="flex flex-wrap space-x-2 w-full justify-between">
      {children}
    </div>
  );
};
