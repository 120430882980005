const Garage = () => {
  return (
    <svg viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M110.586 121.621H28.2295V36.1934H110.586V121.621Z"
        fill="#EDF1FB"
      />
      <path
        d="M111.727 36.1931H27.1326V27.2861H111.727V36.1931Z"
        fill="#EDF1FB"
      />
      <path
        d="M111.727 36.1932H27.1326V31.7617H111.727V36.1932Z"
        fill="#C0D2EB"
      />
      <path
        d="M64.6031 27.2864H53.4146V22.811H64.6031V27.2864Z"
        fill="#C0D2EB"
      />
      <path
        d="M75.7917 27.2863H64.6031V18.3794H75.7917V27.2863Z"
        fill="#ABC6E6"
      />
      <path
        d="M28.2295 102.71H110.586V118.857H28.2295V102.71Z"
        fill="url(#paint0_linear_56_2252)"
      />
      <path
        d="M28.2295 102.71H32.8365V118.857H28.2295V102.71Z"
        fill="#1C405D"
      />
      <path
        d="M43.7617 102.71H48.3688V118.857H43.7617V102.71Z"
        fill="#1C405D"
      />
      <path
        d="M59.3379 102.71H63.9449V118.857H59.3379V102.71Z"
        fill="#1C405D"
      />
      <path
        d="M74.8701 102.71H79.4772V118.857H74.8701V102.71Z"
        fill="#1C405D"
      />
      <path
        d="M90.4463 102.71H95.0533V118.857H90.4463V102.71Z"
        fill="#1C405D"
      />
      <path
        d="M105.979 102.71H110.586V118.857H105.979V102.71Z"
        fill="#1C405D"
      />
      <path
        d="M28.2295 82.9219H110.586V99.0246H28.2295V82.9219Z"
        fill="url(#paint1_linear_56_2252)"
      />
      <path
        d="M28.2295 82.9219H32.8365V99.0246H28.2295V82.9219Z"
        fill="#1C405D"
      />
      <path
        d="M43.7617 82.9219H48.3688V99.0246H43.7617V82.9219Z"
        fill="#1C405D"
      />
      <path
        d="M59.3379 82.9219H63.9449V99.0246H59.3379V82.9219Z"
        fill="#1C405D"
      />
      <path
        d="M74.8701 82.9219H79.4772V99.0246H74.8701V82.9219Z"
        fill="#1C405D"
      />
      <path
        d="M90.4463 82.9219H95.0533V99.0246H90.4463V82.9219Z"
        fill="#1C405D"
      />
      <path
        d="M105.979 82.9219H110.586V99.0246H105.979V82.9219Z"
        fill="#1C405D"
      />
      <path
        d="M28.2295 63.1333H110.586V79.236H28.2295V63.1333Z"
        fill="url(#paint2_linear_56_2252)"
      />
      <path
        d="M28.2295 63.1333H32.8365V79.236H28.2295V63.1333Z"
        fill="#1C405D"
      />
      <path
        d="M43.7617 63.1333H48.3688V79.236H43.7617V63.1333Z"
        fill="#1C405D"
      />
      <path
        d="M59.3379 63.1333H63.9449V79.236H59.3379V63.1333Z"
        fill="#1C405D"
      />
      <path
        d="M74.8701 63.1333H79.4772V79.236H74.8701V63.1333Z"
        fill="#1C405D"
      />
      <path
        d="M90.4463 63.1333H95.0533V79.236H90.4463V63.1333Z"
        fill="#1C405D"
      />
      <path
        d="M105.979 63.1333H110.586V79.236H105.979V63.1333Z"
        fill="#1C405D"
      />
      <path
        d="M28.2295 43.3452H110.586V59.4479H28.2295V43.3452Z"
        fill="url(#paint3_linear_56_2252)"
      />
      <path
        d="M28.2295 43.3452H32.8365V59.4479H28.2295V43.3452Z"
        fill="#1C405D"
      />
      <path
        d="M43.7617 43.3452H48.3688V59.4479H43.7617V43.3452Z"
        fill="#1C405D"
      />
      <path
        d="M59.3379 43.3452H63.9449V59.4479H59.3379V43.3452Z"
        fill="#1C405D"
      />
      <path
        d="M74.8701 43.3452H79.4772V59.4479H74.8701V43.3452Z"
        fill="#1C405D"
      />
      <path
        d="M90.4463 43.3452H95.0533V59.4479H90.4463V43.3452Z"
        fill="#1C405D"
      />
      <path
        d="M105.979 43.3452H110.586V59.4479H105.979V43.3452Z"
        fill="#1C405D"
      />
      <path
        d="M111.727 102.71H27.1326V100.867H111.727V102.71Z"
        fill="#C0D2EB"
      />
      <path
        d="M111.727 121.621H27.1326V119.778H111.727V121.621Z"
        fill="#C0D2EB"
      />
      <path
        d="M111.727 82.9219H27.1326V81.0791H111.727V82.9219Z"
        fill="#C0D2EB"
      />
      <path
        d="M111.727 63.1333H27.1326V61.2905H111.727V63.1333Z"
        fill="#C0D2EB"
      />
      <path
        opacity="0.1"
        d="M112.867 121.621H27.1326L112.867 32.376V121.621Z"
        fill="#020051"
      />
      <defs>
        <linearGradient
          id="paint0_linear_56_2252"
          x1="49.5974"
          y1="81.3421"
          x2="98.8707"
          y2="130.572"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_56_2252"
          x1="49.5974"
          y1="61.554"
          x2="98.8708"
          y2="110.783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_56_2252"
          x1="49.5974"
          y1="41.7654"
          x2="98.8708"
          y2="90.9949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_56_2252"
          x1="49.5974"
          y1="21.9335"
          x2="98.8707"
          y2="71.2069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#89C5F2" />
          <stop offset="1" stop-color="#002F64" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Garage;
