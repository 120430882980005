import React, { useState, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import Textfield from '../../components/textfield/textfield';
import Button from '../../components/button';

type Props = {
  onChange?: (v: number) => void;
  defaultValue?: number;
}

type Option = {
  name: string;
  value: number;
}

const options: Option[] = [
  {
    name: "None",
    value: 0,
  },
  {
    name: "3 days",
    value: 3,
  }, 
  {
    name: "7 days",
    value: 7,
  },
  {
    name: "14 days",
    value: 14,
  },
  {
    name: "30 days",
    value: 30,
  },
  {
    name: "60 days",
    value: 60,
  },
  {
    name: "90 days",
    value: 90,
  },
  {
    name: "180 days",
    value: 180,
  },
  {
    name: "365 days",
    value: 365,
  },
  {
    name: "Custom",
    value: -2,
  },
  {
    name: "Full Available Range",
    value: -1,
  },
];

const MinimumLeaseDropdown = ({ onChange = () => {}, defaultValue = 0 }: Props) => {
  const [selectedOption, setSelectedOption] = useState(options.find((o) => o.value === defaultValue) ?? options[0]);
  const [open, setOpen] = useState(false);
  const [customValue, setCustomValue] = useState('');

  useEffect(() => {
    if(selectedOption.value === -2){
      onChange(parseInt(customValue))
      return
    }

    onChange(selectedOption.value)

  }, [selectedOption, customValue])

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option)
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setOpen(open => !open);
  };

  return (
    <div className=" ml-12 mr-12 relative">
        <div className = "text-left text-md font-semibold mt-[2vh]">
        Minimum Lease Range
      </div>
      <button type = "button"
        className=" mt-[0.5vh]  flex items-center justify-between w-full px-4 py-2 text-left bg-white border border-gray-500 rounded-md drop-shadow-lg focus:outline-none focus:border-gray-800"
        onClick={toggleDropdown}
      >
        <span className = "text-gray-600 text-lg ">{selectedOption.name}</span>
        <IoIosArrowDown className = "text-gray-600 text-xl"/>
      </button>
      {selectedOption.value === -2 &&  (
        <div className="absolute z-50 w-full mt-2 flex justify-between">
          <input
            className="w-[70%] px-4 py-2 border border-gray-300 bg-gray-300 rounded-md focus:outline-none focus:border-gray-800"
            type="number"
            placeholder='Enter a number of days'
            value={customValue}
            onChange={(e) => setCustomValue(e.target.value.toString())}
          />

        </div>
      )}
      {open === true && (
        <div className="absolute z-50 w-full mt-2 bg-white border border-gray-300 rounded-md">
          {options.map((option) => (
            <div
              key={option.value}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                selectedOption === option ? 'bg-gray-100' : ''
              }`}
              onClick={() => handleOptionSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MinimumLeaseDropdown;
